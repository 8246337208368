import { useState } from "react";

const INIT_UNAVAILABLE_PRODUCT_DIALOG_STATE = {
  openUnavailableProductDialog: false,
  titleUnavailableProductDialog: "Important Cart Changes",
  textUnavailableProductDialog:
    "Some products in your cart are no longer available in the vendor’s catalog or their prices have changed. Your cart will now be updated.",
  unavailableProductsIds: null,
};

export const useUnavailableProductDialog = () => {
  const [unavailableProductDialogState, setUnavailableProductDialogState] =
    useState(INIT_UNAVAILABLE_PRODUCT_DIALOG_STATE);

  const handleOpenUnavailableProductDialog = ({ unavailableProductsIds }) => {
    if (!unavailableProductsIds?.length) {
      // eslint-disable-next-line no-console
      return console.error("No unavailableProductsIds");
    }

    setUnavailableProductDialogState({
      ...INIT_UNAVAILABLE_PRODUCT_DIALOG_STATE,
      openUnavailableProductDialog: true,
      unavailableProductsIds,
    });
  };

  const handleCloseUnavailableProductDialog = () => {
    setUnavailableProductDialogState({
      ...INIT_UNAVAILABLE_PRODUCT_DIALOG_STATE,
    });
  };

  return {
    ...unavailableProductDialogState,
    handleOpenUnavailableProductDialog,
    handleCloseUnavailableProductDialog,
  };
};

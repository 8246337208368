import { bool, func, string } from "prop-types";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { CrossIcon, DangerIcon } from "components/Icons";
import { StyledButton } from "components";

export const UnavailableProductDialog = ({
  open,
  title = "",
  text = "",
  onCancel = () => {},
  onAccept = () => {},
  isMobile = false,
}) => {
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          minWidth: isMobile ? "286px" : "420px",
          maxWidth: isMobile ? "286px" : "420px",
          width: isMobile ? "286px" : "420px",
        },
      }}
    >
      <DialogTitle
        sx={{
          p: 0,
          position: "relative",
          width: isMobile ? "286px" : "420px",
          maxWidth: isMobile ? "286px" : "420px",
          minWidth: isMobile ? "286px" : "420px",
        }}
      >
        <Box
          p="16px 16px 2px 16px"
          display="flex"
          alignItems="center"
          gap="8px"
          sx={{
            ...(isMobile && { justifyContent: "center" }),
          }}
        >
          {!isMobile && <DangerIcon size={22} />}
          <Box>
            <Typography
              fontSize={18}
              fontWeight={isMobile ? 500 : 400}
              color={isMobile ? "#000" : "#363531"}
            >
              {title}
            </Typography>
          </Box>
        </Box>

        {!isMobile && (
          <IconButton
            disableRipple
            sx={{ position: "absolute", right: 6, top: 10 }}
            onClick={onCancel}
          >
            <CrossIcon />
          </IconButton>
        )}
      </DialogTitle>

      <DialogContent
        sx={{
          px: isMobile ? "24px" : "16px",
          paddingTop: "10px !important",
          pb: "18px",
          maxWidth: "420px",
          ...(isMobile && { textAlign: "center" }),
        }}
      >
        <Typography
          fontSize={13}
          fontWeight={isMobile ? 400 : 300}
          color={isMobile ? "#000" : "#363531"}
          lineHeight={isMobile ? 1.5 : 1.2}
        >
          {text}
        </Typography>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: isMobile ? "center" : "flex-end",
          padding: isMobile ? "6px 16px 16px 16px" : "16px",
          ...(!isMobile && { borderTop: "0.5px solid #CCCCCC" }),
        }}
      >
        {!!isMobile && (
          <StyledButton
            label="Close"
            variant="outlined"
            color="grey"
            sx={{
              width: "100%",
              height: "36px",
              borderRadius: "4px",
              ...(isMobile && { "& span": { fontSize: "15px" } }),
            }}
            onClick={onCancel}
          />
        )}

        <StyledButton
          label="Proceed"
          variant="contained"
          sx={{
            width: isMobile ? "100%" : "110px",
            height: isMobile ? "36px" : "28px",
            borderRadius: "4px",
            ...(isMobile && { "& span": { fontSize: "15px" } }),
          }}
          onClick={onAccept}
        />
      </DialogActions>
    </Dialog>
  );
};

UnavailableProductDialog.propTypes = {
  open: bool,
  title: string,
  text: string,
  onCancel: func,
  onAccept: func,
  isMobile: bool,
};

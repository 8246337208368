import { array, bool, func, object, string } from "prop-types";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  MenuItem,
  Typography,
} from "@mui/material";
import { CrossIcon } from "components/Icons";
import { photoUrl } from "helpers/helpers";
import { STORE_LABELS } from "utils/constants";
import { StyledButton, StyledSelect } from "components";

export const RequestWholesaleDialog = ({
  open,
  handleCloseOrderDirectAccess,
  classes,
  brandLogo,
  name,
  shippingAddress,
  hideRequestPaymentTerms,
  paymentTermsId,
  handleChangePayterm,
  paymentTerms,
  disabledBtn,
  handleRequestWholesale,
  loading,
}) => {
  return (
    <Dialog
      PaperProps={{ sx: { width: "742px", maxWidth: "742px" } }}
      open={open}
      onClose={handleCloseOrderDirectAccess}
    >
      <DialogContent className={classes.dialogWrap}>
        <IconButton
          className={classes.closeButton}
          onClick={handleCloseOrderDirectAccess}
        >
          <CrossIcon />
        </IconButton>
        <Box className={classes.contentWrap}>
          <Box className={classes.storeInfoWrap}>
            <Box
              className={classes.logoWrap}
              component="img"
              src={photoUrl(brandLogo?.fileName)}
            />
            <Box textAlign="center">
              <Typography className={classes.storeName}>{name}</Typography>
              <Typography className={classes.storeAddress}>
                {shippingAddress?.city}, {shippingAddress?.state}
              </Typography>
            </Box>
          </Box>
          <Box className={classes.labelsWrap}>
            {STORE_LABELS.map(({ label, icon }) => (
              <Box className={classes.storeLabel} key={label}>
                <Typography noWrap>{label}</Typography>
                {icon}
              </Box>
            ))}
          </Box>
          <Box className={classes.selectWrap}>
            {hideRequestPaymentTerms ? null : (
              <StyledSelect
                color={paymentTermsId ? "#5F6267" : "#B5B5AC"}
                fontSize="18px"
                value={paymentTermsId}
                fullWidth
                height="48px"
                displayEmpty
                onChange={(e) => handleChangePayterm(e.target.value)}
              >
                <MenuItem sx={{ display: "none" }} value="">
                  Request Payment Terms
                </MenuItem>

                {paymentTerms?.map((term) => (
                  <MenuItem key={term.id} value={term.id}>
                    {term.name}
                  </MenuItem>
                ))}
              </StyledSelect>
            )}
            <StyledButton
              disabled={disabledBtn}
              onClick={handleRequestWholesale}
              className={classes.requestButton}
              variant="contained"
              label={loading ? "Loading..." : "Request Wholesale Access"}
            />
          </Box>
        </Box>
        <Box className={classes.footer}>
          <Typography>Wholesale Made Simple</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

RequestWholesaleDialog.propTypes = {
  open: bool,
  handleCloseOrderDirectAccess: func,
  classes: object,
  brandLogo: object,
  shippingAddress: object,
  hideRequestPaymentTerms: bool,
  paymentTermsId: string,
  handleChangePayterm: func,
  paymentTerms: array,
  disabledBtn: bool,
  handleRequestWholesale: func,
  loading: bool,
  name: string,
};
RequestWholesaleDialog.defaultProps = {
  open: false,
  handleCloseOrderDirectAccess: () => {},
  classes: {},
  brandLogo: {},
  shippingAddress: {},
  hideRequestPaymentTerms: false,
  paymentTermsId: "",
  handleChangePayterm: () => {},
  paymentTerms: [],
  disabledBtn: false,
  handleRequestWholesale: () => {},
  loading: false,
  name: "",
};

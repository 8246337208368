import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  // DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  Button,
} from "@mui/material";
import {
  AddCardDialog,
  ConfirmCardDelete,
  StyledButton,
  StyledLabel,
  StyledTextField,
  StyledTooltip,
} from "components";
import { bool, func, object, string } from "prop-types";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useCustomerCards } from "./CustomerCards.hooks";
import { startCase } from "lodash";
import useStyles from "./styles";
import { CrossIcon } from "components/Icons";
import NumberFormat from "react-number-format";
import EditPaymentPopup from "pages/CartPage/components/EditPaymentPopup/EditPaymentPopup";
import { ConfirmContext } from "pages/MasterPage/MasterPage";
import ScrollControlWrapper from "components/ScrollControlWrapper/ScrollControlWrapper";

export const CustomerCards = ({
  open,
  onClose: onCloseProp,
  distributor,
  customerId,
  billingAddress,
  handleConfirm,
  amountData,
  removeDraftCard,
  draftCardId,
  setCardToCart,
  resetCardCart,
}) => {
  const classes = useStyles();
  const {
    handleFetchCards,
    errors,
    creditCards,
    isCardExpired,
    tooltipCard,
    setOpenTooltip,
    selectedCard,
    lastCardId,
    addCardOpen,
    handleCloseAddCard,
    handleOpenAddCard,
    handleDeleteCard,
    selectCard,
    loading,
    editCard,
    editCardOpen,
    handleCloseEditCard,
    handleSaveEditCard,
    handleOpenEditCard,
    // handleResetState,
    handleSetDefault,
  } = useCustomerCards({
    distributor,
    customerId,
    removeDraftCard,
    draftCardId,
    setCardToCart,
    resetCardCart,
  });

  const { setConfirmState } = useContext(ConfirmContext);

  const [focused, setFocused] = useState(false);
  const [showPaymentField, setShowPaymentField] = useState(false);

  const onClose = () => {
    onCloseProp();
    setConfirmState((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setShowPaymentField(false);
  };

  const amountError = useMemo(
    () =>
      amountData?.setAmount &&
      (!amountData?.amount ||
        parseFloat(amountData?.amount) > amountData?.balance),
    [amountData?.amount, amountData?.balance, amountData?.setAmount]
  );

  const amountRef = useRef(null);
  const [openTooltipAmount, setOpenTooltipAmount] = useState(false);

  useEffect(() => {
    setOpenTooltipAmount(
      (amountData?.amount < 0 || amountData?.amount > amountData?.balance) &&
        open
    );
  }, [amountData?.amount, amountData?.balance, open]);

  return (
    <>
      <Dialog
        PaperProps={{
          sx: {
            borderRadius: "8px",
            opacity: (addCardOpen || editCardOpen) && 0,
            maxWidth: "359px",
          },
        }}
        fullWidth
        maxWidth="xs"
        open={open}
        onClose={onClose}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Box display="flex" alignItems="center" gap="15px">
            Select payment method
            {loading && <CircularProgress size={23} />}
          </Box>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </DialogTitle>
        {!!amountData?.setAmount && (
          <>
            <Box
              sx={{
                backgroundColor: "rgba(71, 160, 110, 0.15)",
                justifyContent: "start",
                alignItems: "center",
                py: 1,
                px: 3,
              }}
            >
              <Box
                sx={{
                  fontSize: 12,
                  fontWeight: 400,
                  color: "#5F6267",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    fontSize: 12,
                    display: "flex",
                    fontWeight: 400,
                    color: "black",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontSize: 12 }}>Amount: </Typography>
                  <Typography sx={{ fontSize: 12 }}> Payments: </Typography>
                  <Typography sx={{ fontSize: 12 }}>Balance </Typography>
                </Box>
                <Box
                  sx={{
                    fontSize: 12,
                    display: "flex",
                    fontWeight: 500,
                    paddingLeft: "20px",
                    color: "#5F6267",
                    flexDirection: "column",
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: 300 }}>
                    ${amountData?.order?.totalAmount?.toFixed(2) || "-"}{" "}
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: 300 }}>
                    (${amountData?.order?.totalPayments?.toFixed(2) || 0})
                  </Typography>
                  <Typography sx={{ fontSize: 12, fontWeight: "500" }}>
                    ${amountData?.balance || 0}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <StyledTooltip
              open={openTooltipAmount}
              title="Payment cannot exceed the balance amount"
              placement="top"
              arrow
              PopperProps={{
                modifiers: [{ name: "offset", options: { offset: [0, -8] } }],
              }}
            >
              <Box ref={amountRef} mt={1} px={3}>
                {showPaymentField ? (
                  <NumberFormat
                    customInput={StyledTextField}
                    InputProps={{
                      startAdornment: (
                        <Box
                          mt="-4px"
                          color={amountData?.amount ? "#212121" : "#A2A2A2"}
                        >
                          $
                        </Box>
                      ),
                    }}
                    sx={{
                      mt: 1,
                      color: "#47A06D",
                      "& .MuiInput-root:before": { borderColor: "#47A06D" },
                    }}
                    variant="standard"
                    fullWidth
                    size="small"
                    placeholder="0.00"
                    decimalScale={2}
                    thousandSeparator
                    allowNegative={false}
                    fixedDecimalScale={!focused}
                    onBlur={() => setFocused(false)}
                    onFocus={() => setFocused(true)}
                    value={amountData?.amount}
                    onValueChange={({ value }) => {
                      amountData.setAmount(+value === 0 ? "" : +value);
                    }}
                    onChange={() => {}}
                  />
                ) : (
                  <Box
                    sx={{
                      // borderBottom: "1px solid #47A06D",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography fontSize={17} color="#707070">
                      Payment amount{" "}
                      <Box component="span" color="#000">
                        ${amountData?.amount}
                      </Box>
                    </Typography>

                    <Button
                      sx={{
                        fontSize: 14,
                        color: "#1C1C19",
                        "&:hover": {
                          textDecoration: "underline",
                          bgcolor: "transparent",
                        },
                      }}
                      onClick={() => setShowPaymentField(true)}
                    >
                      Edit
                    </Button>
                  </Box>
                )}
              </Box>
            </StyledTooltip>
          </>
        )}
        <Box sx={{ px: "24px", pb: "20px" }}>
          <StyledButton
            label="Add credit card"
            fontSize="17px"
            startIcon={<CrossIcon fill="#DDD" />}
            variant="outlined"
            color="blackBtn"
            fullWidth
            sx={{
              height: "78px",
              justifyContent: "flex-start",
              border: "0.5px solid #DBE2ED",
              "& svg": {
                mr: "6px",
                transform: "rotate(45deg)",
              },
              px: 3,
            }}
            onClick={handleOpenAddCard}
          />
        </Box>

        <ScrollControlWrapper
          id="cards-scroll-popup"
          maxHeight={!amountData?.setAmount ? "340px" : "230px"}
        >
          {
            creditCards.length ? (
              <InfiniteScroll
                dataLength={creditCards?.length || 0}
                hasMore={!!lastCardId}
                loader={
                  <CircularProgress sx={{ ml: "50%", mt: "2px" }} size="20px" />
                }
                next={() => handleFetchCards(true)}
                scrollableTarget="cards-scroll-popup"
              >
                <Grid
                  sx={{
                    gap: 1,
                    marginTop: 1.5,
                    marginBottom: 1.5,
                    px: 3,
                  }}
                  component={RadioGroup}
                  container
                  onChange={(e, newVal) => selectCard(newVal)}
                >
                  {errors?.newCard && (
                    <Box sx={{ width: "100%", textAlign: "center" }}>
                      <Typography
                        fontSize="14px"
                        color="#FF6969"
                        fontWeight="400"
                      >
                        {errors.newCard.message}
                      </Typography>
                    </Box>
                  )}

                  {creditCards?.map((card) => {
                    const hasExpiresDateIsDecline = isCardExpired(
                      card?.expMonth,
                      card?.expYear
                    );

                    return (
                      <StyledTooltip
                        key={card?.id}
                        title={
                          "The card has expired. Check the expiration date or use a different card."
                        }
                        placement="top"
                        arrow
                        open={
                          hasExpiresDateIsDecline &&
                          tooltipCard?.id === card?.id
                        }
                        onOpen={() => setOpenTooltip(card)}
                        onClose={() => setOpenTooltip(null)}
                      >
                        <Box
                          sx={{
                            border: "1px solid #DBE2ED",
                            borderRadius: "4px",
                            width: "100%",
                            px: 1,
                            height: "78px",
                            display: "flex",
                            backgroundColor:
                              selectedCard?.id === card?.id
                                ? "rgba(66, 165, 127, 0.12)"
                                : "",
                          }}
                        >
                          <FormControlLabel
                            sx={{
                              width: "100%",
                              mr: 0,
                            }}
                            checked={selectedCard?.id === card.id}
                            value={card?.id}
                            control={<Radio />}
                            disabled={hasExpiresDateIsDecline}
                            label={
                              <Box
                                sx={{
                                  pl: 1,
                                  maxWidth: "160px",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 1,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      fontWeight: 500,
                                      color: "#363531",
                                    }}
                                  >
                                    {startCase(card?.brand)} ****
                                    {card?.last4}
                                  </Typography>
                                  {hasExpiresDateIsDecline ? (
                                    <StyledLabel
                                      text="expired"
                                      style={{ pb: "2px" }}
                                    />
                                  ) : (
                                    card?.defaultMethod && (
                                      <StyledLabel style={{ pb: "4px" }} />
                                    )
                                  )}
                                </Box>
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: "#363531",
                                  }}
                                  noWrap
                                >
                                  {card?.billingAddress?.name || card?.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: 12,
                                    fontWeight: 400,
                                    color: "#363531",
                                  }}
                                >
                                  Expires {card?.expMonth}/{card?.expYear}
                                </Typography>
                              </Box>
                            }
                          />
                          {!hasExpiresDateIsDecline && (
                            <Box className={classes.btnsBlock}>
                              <StyledButton
                                label="Edit"
                                variant="outlined"
                                color="ground"
                                fontSize="8px"
                                fontWeight="400"
                                className={classes.smallBtn}
                                onClick={() => {
                                  handleOpenEditCard(card);
                                }}
                              />
                              <StyledButton
                                label="Remove"
                                variant="outlined"
                                color="ground"
                                className={classes.smallBtn}
                                fontSize="8px"
                                fontWeight="400"
                                onClick={() => {
                                  setConfirmState((prev) => ({
                                    ...prev,
                                    onConfirm: () => {
                                      onClose();
                                      handleDeleteCard(card?.id);
                                    },
                                    title: "Delete payment method?",
                                    isOpen: true,
                                    cardStateItem: card,
                                    type: "dialog",
                                    titleElement: (
                                      <ConfirmCardDelete card={card} />
                                    ),
                                  }));
                                }}
                              />
                              {!card.defaultMethod && (
                                <StyledButton
                                  label="Set Default"
                                  variant="outlined"
                                  color="ground"
                                  className={classes.smallBtn}
                                  fontSize="8px"
                                  fontWeight="400"
                                  onClick={() => handleSetDefault(card)}
                                />
                              )}
                            </Box>
                          )}
                        </Box>
                      </StyledTooltip>
                    );
                  })}
                </Grid>
              </InfiniteScroll>
            ) : null
            // <Box className={classes.noCardsPlaceholder}>No cards found</Box>
          }
        </ScrollControlWrapper>
        {!!creditCards.length && (
          <DialogActions className={classes.buttonsWrap}>
            <StyledButton label="Cancel" color="ground" onClick={onClose} />
            <StyledTooltip
              arrow
              title="Add credit card to proceed"
              placement="top"
              disableHoverListener={!!creditCards?.length}
            >
              <Box ml={1}>
                <StyledButton
                  label={amountData?.setAmount ? "Process payment" : "Proceed"}
                  disabled={
                    !creditCards?.length || !selectedCard?.id || amountError
                  }
                  variant="contained"
                  onClick={() => {
                    handleConfirm(selectedCard);
                    onClose();
                  }}
                />
              </Box>
            </StyledTooltip>
          </DialogActions>
        )}
        <AddCardDialog
          isOpen={addCardOpen}
          handleClose={handleCloseAddCard}
          billingAddress={billingAddress}
          customerId={customerId}
          customerCreditCards={creditCards}
          handleSave={(_, isDefault) => handleFetchCards(false, isDefault)}
        />
        <EditPaymentPopup
          isOpen={editCardOpen}
          customerId={customerId}
          handleClose={handleCloseEditCard}
          card={editCard}
          handleSave={handleSaveEditCard}
        />
      </Dialog>
    </>
  );
};

export default CustomerCards;

CustomerCards.propTypes = {
  open: bool,
  onClose: func,
  resetCardCart: func,
  distributor: object,
  customerId: string,
  billingAddress: object,
  handleConfirm: func,
  amountData: object,
  removeDraftCard: func,
  draftCardId: string,
  setCardToCart: func,
};

CustomerCards.defaultProps = {
  open: false,
  onClose: () => {},
  resetCardCart: () => {},
  distributor: null,
  customerId: "",
  billingAddress: null,
  handleConfirm: () => {},
  amountData: {},
  removeDraftCard: () => {},
  setCardToCart: () => {},
};

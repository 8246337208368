import { useMemo, useState } from "react";
import { initialErrPhoneStatus } from "./RegistrationPage.constants";
import { useNavigate } from "react-router-dom";
import {
  confirmUserService,
  getCurrentUser,
  registerUserService,
} from "services/account";
import { error, errorConfig } from "helpers/notifications";
import { wholesaleAccessService } from "services/distributors";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../../firebase/notifications/config";
import { toast } from "react-toastify";
import { getTimeZoneService } from "services/googleMaps";
import {
  setTokenToAxios,
  setTokenToLocalStorage,
  setUserRoleToLocalStorage,
} from "helpers/auth";
import { setCurrentUser } from "redux/actions/auth";
import { useDispatch } from "react-redux";

export const useSubmit = ({
  setError,
  distributorData,
  setValue,
  stateQuery,
  // brandName,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [errPhoneStatus, setErrPhoneStatus] = useState(initialErrPhoneStatus);

  const hasDistributor = useMemo(() => {
    return !!distributorData?.id;
  }, [distributorData?.id]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const { step, countryPrefix, businessPhone } = data;

    const updateStepParam = (newStep) => {
      const searchParams = new URLSearchParams(stateQuery);
      searchParams.set("stepParam", newStep);
      return `?${searchParams.toString()}`;
    };

    if (step === 1) {
      setIsLoading(false);
      setValue("step", 2);
      navigate(`/registration/step-2${stateQuery}`);
    }
    if (step === 2) {
      const params = { token: data?.regParams?.tokenParam };

      try {
        const uploadData = {
          email: data?.businessEmail,
          password: data?.password,
          name: data?.name,
          phone: `${
            businessPhone.includes("+") ? "" : countryPrefix?.phone_code
          }${businessPhone}`.replace(/\s|\(|\)/g, ""),
          billingAddress: data?.billingAddress,
          shippingAddress: data?.shippingAddress,
          distributorId: distributorData?.id,
        };

        //if (data?.federalTaxIdEin)
        //  uploadData.federalTaxId = parseInt(
        //    data?.federalTaxIdEin?.replace("-", ""),
        //    10
        //  );

        const { lat, lng } = data?.shippingAddress || {};

        const { timeZoneId } = await getTimeZoneService(
          lat,
          lng,
          Math.round(new Date().getTime() / 1000)
        );

        if (timeZoneId) uploadData.timeZone = timeZoneId;

        const { token = "" } = await registerUserService(uploadData, params);
        setValue("token", token);
        setValue("step", 2.1);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err?.response?.data?.message || "Something went wrong!");
        if (/phone/.test(err?.response?.data?.message)) {
          setErrPhoneStatus((prev) => ({
            ...prev,
            status: true,
            message: "Not valid phone number!",
            value: businessPhone,
          }));
        } else {
          toast.error(
            err?.response?.data?.message || "Something went wrong.",
            errorConfig
          );
        }
      } finally {
        setIsLoading(false);
      }
    }
    if (step === 2.1) {
      setValue("step", 2.1);
      const token = data?.token ? data?.token : data?.regParams?.tokenParam;
      try {
        const uploadData = {
          code: data?.code,
        };
        await confirmUserService(uploadData, token);
        setValue("step", 3);
        navigate(`/registration/step-3${updateStepParam("complete")}`);
      } catch (err) {
        setError("code", { message: "Wrong code" });
        // eslint-disable-next-line no-console
        console.error(err.message);
      } finally {
        setIsLoading(false);
      }
    }
    if (step === 3) {
      const token = data?.token ? data?.token : data?.regParams?.tokenParam;
      if (hasDistributor) {
        const preparedData = {
          newDistributor: false,
          distributorId: distributorData?.id,
        };

        if (!data?.hideRequestPaymentTerms && data?.payTermId)
          preparedData.paymentTermsId = Number(data.payTermId);

        try {
          const { appCustomerId } = await wholesaleAccessService(
            preparedData,
            token
          );

          if (!appCustomerId) throw new Error("Something went wrong!");

          const uploadData = { customerId: appCustomerId, read: false };

          const fetchCollection = await getDoc(
            doc(firestore, "distributors-direct-statuses", distributorData?.id)
          );
          let dataCollection;
          if (fetchCollection.exists()) {
            dataCollection = fetchCollection.data()?.customers;
          } else {
            dataCollection = [];
          }

          await setDoc(
            doc(firestore, "distributors-direct-statuses", distributorData?.id),
            {
              customers: [...dataCollection, uploadData],
            }
          );
        } catch (err) {
          error(err.message);
          // eslint-disable-next-line no-console
          console.error(err.message);
        }
      }

      setTokenToLocalStorage(token);
      setUserRoleToLocalStorage("CUSTOMER");
      setTokenToAxios(token);

      try {
        if (token) {
          const user = await getCurrentUser();

          dispatch(setCurrentUser(user));

          navigate("/");
        }
      } catch (err) {
        error(err.message);
        // eslint-disable-next-line no-console
        console.error(err.message);
      }
    }
  };

  return { onSubmit, errPhoneStatus, isLoading };
};

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { createSelector } from "reselect";
import { error } from "helpers/notifications";
// import { getDistributorsAction } from "redux/actions/distributors";
import { addDraftAction, updateDraftsAction } from "redux/actions/drafts";
import { currentUserSelector } from "redux/selectors/auth";
import { draftsListSelector } from "redux/selectors/drafts";
import { getDistributorByIdService } from "services/distributors";
import { useShowProductsWithoutLogIn } from "helpers/hooks";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";
import { SuppliersContext } from "pages/MasterPage/MasterPage";

const selector = createSelector(
  draftsListSelector,
  currentUserSelector,
  (draftsList, currentUser) => ({
    draftsList,
    currentUser,
  })
);

export const useProductDetailsPage = ({
  isMobile,
  state,
  loading,
  product,
  drawerState,
  setShowSelectProducts,
  distributorInfo,
}) => {
  const { draftsList, currentUser } = useSelector(selector);
  const { orderDirect } = currentUser || {};

  const { isShowProductsWithoutLogIn } = useShowProductsWithoutLogIn();

  const [currentProductIndex, setCurrentProductIndex] = useState(0);

  const initialSlide = useMemo(() => {
    return state?.product?.index ?? 0;
  }, [state?.product?.index]);

  const dispatch = useDispatch();

  const { photos, childProducts } = product || {};

  const { control, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      products: [],
    },
  });

  const { products: productsList } = useWatch({ control });

  const currentProduct = useMemo(() => {
    const productByIndex = productsList?.[currentProductIndex];
    const isChild = productByIndex
      ? // eslint-disable-next-line no-prototype-builtins
        !productByIndex?.hasOwnProperty("childProducts")
      : false;

    return isChild
      ? {
          ...productByIndex,
          distributor: product?.distributor,
          sellingOutOfStock: product?.sellingOutOfStock,
        }
      : productByIndex || null;
  }, [
    currentProductIndex,
    product?.distributor,
    product?.sellingOutOfStock,
    productsList,
  ]);

  const childProductsPhotos = useMemo(
    () =>
      childProducts
        ? childProducts?.reduce((accumulator, currentValue) => {
            return currentValue?.photos?.length
              ? [...accumulator, ...currentValue.photos]
              : accumulator;
          }, [])
        : [],
    [childProducts]
  );

  const resetView = useCallback(() => {
    const products = product?.isMultiple
      ? product?.childProducts.map((p, i) => ({
          ...p,
          distributorId: product?.distributorId,
          value: 0,
          index: i,
        })) || []
      : [{ ...product, value: 0, index: 0 }];

    reset({ products });
  }, [product, reset]);

  useEffect(() => {
    resetView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const productPhotos = useMemo(() => {
    if (isMobile) {
      if (product?.isMultiple) {
        return product?.childProducts.map((p) => ({
          ...p?.photos?.[0],
          sku: p?.sku,
          color: p?.color,
          size: p?.size,
        }));
      } else {
        return [
          photos?.[0]?.id
            ? {
                ...photos?.[0],
                sku: product?.sku,
                color: product?.color,
                size: product?.size,
              }
            : { sku: product?.sku, color: product?.color, size: product?.size },
        ];
      }
    }

    return product ? [...photos, ...childProductsPhotos] : [];
  }, [isMobile, childProductsPhotos, photos, product]);

  // useEffect(() => {
  //   dispatch(getDistributorsAction({}));
  // }, [dispatch]);

  const selectedProducts = productsList.filter(({ value }) => value > 0);

  const { distributorId } = product || {};
  const currentDraft = draftsList?.length
    ? draftsList?.find(({ distributor }) => distributor.id === distributorId)
    : null;

  const handleAddToCartDesktop = () => {
    const newProductsData = currentDraft?.data
      ? [...currentDraft.data.productsData]
      : [];
    setShowSelectProducts(selectedProducts);
    selectedProducts.forEach((selected) => {
      const newProduct = {
        id: selected.id,
        quantity: selected.value,
        manufacturerDiscountData: selected.manufacturerDiscountData || [],
        itemDiscountAmount: selected.itemDiscountAmount || 0,
        itemDiscountType: selected.itemDiscountType || "PERCENTAGE",
        minOrderQTY: selected.minOrderQTY,
        price: selected.wholesalePrice,
      };

      const foundInDraftIndex = currentDraft?.data?.productsData?.findIndex(
        ({ id }) => id === newProduct.id
      );
      if (foundInDraftIndex > -1)
        newProductsData.splice(foundInDraftIndex, 1, {
          ...newProduct,
          quantity:
            newProduct.quantity +
            currentDraft?.data?.productsData[foundInDraftIndex].quantity,
        });
      else {
        newProductsData.push(newProduct);
      }
    });

    const uploadDraft = {
      ...currentDraft?.data,
      productsData: newProductsData,
    };

    if (currentDraft) {
      dispatch(updateDraftsAction(uploadDraft, distributorId, currentDraft.id));
      resetView();
    } else {
      dispatch(addDraftAction(uploadDraft, distributorId));
      resetView();
    }

    if (!isMobile) drawerState?.handleOpen();
  };

  const [profileState, setProfileState] = useState({
    open: false,
    distributor: null,
    loading: false,
  });

  const handleViewProfile = async () => {
    const { distributorId } = product;
    if (profileState.loading) return;
    try {
      setProfileState((prev) => ({ ...prev, loading: true }));
      const res = await getDistributorByIdService(distributorId);
      setProfileState((prev) => ({
        ...prev,
        loading: false,
        distributor: res,
        open: true,
      }));
    } catch (err) {
      setProfileState((prev) => ({ ...prev, loading: false }));

      error(err?.response?.data?.message);
    }
  };

  const setShowMSRP = useMemo(() => {
    const supplierData = orderDirect?.find(
      (s) => s?.distributor?.id === distributorInfo?.distributorId
    );
    return supplierData?.distributor?.showRetailPrice;
  }, [orderDirect, distributorInfo]);

  const { sellingOutOfStock, minOrderQTY } = product || {};

  const { inventory: inv } = currentProduct || {};
  const { onHand, allocated } = inv || {};

  const productSellingOutOfStock = sellingOutOfStock;
  const productMinOrderQTY = minOrderQTY;
  const getAvailableQTY = onHand - allocated;

  const [productQty, setProductQty] = useState(0);

  const handleGetCurrentDraft = useCallback(
    (id) => {
      return draftsList?.length
        ? draftsList?.find(({ distributor }) => distributor?.id === id)
        : null;
    },
    [draftsList]
  );

  const handleAddToCart = useCallback(
    ({ product, productQty, isAdded, isAlreadyChangedAndAdded }) => {
      const {
        id,
        wholesalePrice,
        manufacturerDiscountData,
        itemDiscountAmount,
        minOrderQTY,
        distributor,
        distributorId,
        itemDiscountType,
      } = product || {};

      const { id: distId } = distributor || {};

      const currentDraft = handleGetCurrentDraft(distId || distributorId);

      const newProductsData = [...(currentDraft?.data?.productsData || [])];

      if (isAdded && !isAlreadyChangedAndAdded) {
        const filteredProductsData = newProductsData.filter(
          (item) => item.id !== id
        );

        const uploadDraft = {
          ...currentDraft?.data,
          productsData: filteredProductsData,
        };

        return dispatch(
          updateDraftsAction(uploadDraft, distributorId, currentDraft.id)
        );
      }

      if (isAlreadyChangedAndAdded) {
        const productForReplace = newProductsData.filter(
          (item) => item.id === id
        )[0];
        const filteredProductsData = newProductsData.filter(
          (item) => item.id !== id
        );

        const uploadDraft = {
          ...currentDraft?.data,
          productsData: [
            ...filteredProductsData,
            { ...productForReplace, quantity: productQty },
          ],
        };

        return dispatch(
          updateDraftsAction(uploadDraft, distributorId, currentDraft.id)
        );
      }

      const newProduct = {
        id,
        quantity: productQty,
        manufacturerDiscountData: manufacturerDiscountData || [],
        itemDiscountAmount: itemDiscountAmount || 0,
        itemDiscountType: itemDiscountType || "PERCENTAGE",
        minOrderQTY: minOrderQTY,
        price: wholesalePrice,
      };

      newProductsData.push(newProduct);

      const uploadDraft = {
        ...currentDraft?.data,
        productsData: newProductsData,
      };

      if (currentDraft)
        return dispatch(
          updateDraftsAction(uploadDraft, distributorId, currentDraft.id)
        );
      return dispatch(addDraftAction(uploadDraft, distributorId));
    },
    [dispatch, handleGetCurrentDraft]
  );

  const getDefaultProductValue = useCallback(
    (product) => {
      const { id, distributor, distributorId } = product || {};

      const currentDraft = handleGetCurrentDraft(
        distributor?.id || distributorId
      );

      const filteredProductsData = currentDraft?.data?.productsData?.filter(
        (item) => item?.id === id
      );

      if (filteredProductsData && filteredProductsData?.length)
        return filteredProductsData?.[0]?.quantity;
      return 0;
    },
    [handleGetCurrentDraft]
  );

  const handleIsAlreadyAdded = useCallback(
    (product) => {
      const currentDraft = handleGetCurrentDraft(
        product?.distributor?.id || product?.distributorId
      );

      return !!currentDraft?.data?.productsData?.find(
        (item) => item.id === product.id
      );
    },
    [handleGetCurrentDraft]
  );

  const isAdded = useMemo(
    () => handleIsAlreadyAdded(currentProduct),
    [handleIsAlreadyAdded, currentProduct]
  );

  const isLowerThanMOQ = useMemo(
    () =>
      !productSellingOutOfStock &&
      productMinOrderQTY &&
      currentProduct?.type === PRODUCT_TYPE_INVENTORY.inventory &&
      getAvailableQTY < productMinOrderQTY,
    [
      currentProduct?.type,
      getAvailableQTY,
      productMinOrderQTY,
      productSellingOutOfStock,
    ]
  );

  useEffect(() => {
    const defaultValue = getDefaultProductValue(currentProduct);
    const preparedDefaultMinOrderQTY = isLowerThanMOQ ? 0 : productMinOrderQTY;

    setProductQty(defaultValue > 0 ? defaultValue : preparedDefaultMinOrderQTY);
  }, [
    isAdded,
    getDefaultProductValue,
    currentProduct,
    productMinOrderQTY,
    isLowerThanMOQ,
  ]);

  const { suppliersState } = useContext(SuppliersContext);

  const showRetailPrice = useCallback(
    (product) => {
      const { distributor } = product || {};
      if (orderDirect?.length === 1)
        return suppliersState?.list?.[0]?.showRetailPrice;

      const currentProductSupplier = suppliersState?.list?.find(
        (od) => od?.id === distributor?.id
      );

      return currentProductSupplier?.showRetailPrice;
    },
    [orderDirect?.length, suppliersState?.list]
  );

  const showMOQTooltip = productMinOrderQTY
    ? !!productQty && +productQty < productMinOrderQTY
    : productQty && +productQty < 0;

  const showStockTooltip =
    currentProduct?.type === PRODUCT_TYPE_INVENTORY.inventory &&
    !productSellingOutOfStock &&
    productQty > getAvailableQTY;

  const handleGetQTYFromDraft = (draft, productId) => {
    if (!!draft && !!draft?.data?.productsData?.length) {
      const productFromDraft = draft?.data?.productsData.filter(
        (product) => product.id === productId
      );

      if (productFromDraft.length) return productFromDraft?.[0]?.quantity;
      return null;
    }
    return null;
  };

  const draftQTY = isAdded
    ? handleGetQTYFromDraft(currentDraft, currentProduct?.id)
    : 0;

  const isAlreadyChangedAndAdded =
    isAdded && draftQTY && productQty !== draftQTY;

  const supplierApproved = useCallback(
    (product) => {
      const { distributor } = product || {};

      if (orderDirect?.length === 1)
        return orderDirect?.[0]?.status === "APPROVED";

      const currentProductSupplier = orderDirect?.find(
        (od) => od?.distributor?.id === distributor?.id
      );

      return currentProductSupplier?.status === "APPROVED";
    },
    [orderDirect]
  );

  return {
    currentUser,
    draftsList,
    control,
    reset,
    productsList,
    childProductsPhotos,
    resetView,
    productPhotos,
    selectedProducts,
    currentDraft,
    handleAddToCart: isMobile ? handleAddToCart : handleAddToCartDesktop,
    profileState,
    setProfileState,
    handleViewProfile,
    setShowMSRP,
    currentProductIndex,
    setCurrentProductIndex,
    currentProduct,
    initialSlide,

    isShowProductsWithoutLogIn,
    isLowerThanMOQ,
    productQty,
    setProductQty,
    productSellingOutOfStock,
    isAdded,
    productMinOrderQTY,
    showMOQTooltip,
    showStockTooltip,
    isAlreadyChangedAndAdded,
    showRetailPrice: showRetailPrice(product),
    supplierApproved: supplierApproved(product),
  };
};

import { error, success } from "helpers/notifications";
import {
  completeRegistrationService,
  updateProfileService,
} from "../../services/account";

export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const DELETE_CURRENT_USER = "DELETE_CURRENT_USER";
export const SET_AUTH_LOADING = "SET_AUTH_LOADING";

export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});

export const deleteCurrentUser = () => ({
  type: SET_CURRENT_USER,
});

export const setAuthLoading = (payload) => ({
  type: SET_AUTH_LOADING,
  payload,
});

export const updateProfileAction = ({
  data,
  onSuccess,
  onError,
  successMessage,
}) => {
  return (dispatch) => {
    dispatch(setAuthLoading(true));
    updateProfileService(data)
      .then((res) => {
        dispatch(setCurrentUser(res));
        dispatch(setAuthLoading(false));
        if (onSuccess) onSuccess(res);
        success(successMessage ? successMessage : "Profile updated");
      })
      .catch((err) => {
        dispatch(setAuthLoading(false));
        error(err?.response?.data?.message || "Something went wrong.");
        if (onError) onError(err);
      });
  };
};

export const completeRegistrationAction = (data, onSuccess) => {
  return (dispatch) => {
    dispatch(setAuthLoading(true));
    completeRegistrationService(data)
      .then((res) => {
        dispatch(setCurrentUser(res));
        dispatch(setAuthLoading(false));
        success("Registration completed");
        onSuccess();
      })
      .catch((err) => {
        dispatch(setAuthLoading(false));
        error(err?.response?.data?.message || "Something went wrong.");
      });
  };
};

export const updateUserFieldAction = (field, value) => {
  return (dispatch, getState) => {
    const state = getState();
    const currentUser = state.auth.currentUser;
    dispatch(setCurrentUser({ ...currentUser, [field]: value }));
  };
};

import { useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { validationSchema } from "./LoginPage.validations";
import { yupResolver } from "@hookform/resolvers/yup";

import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import { LoginFooter, RegistrationBottomBlock } from "components";
import { LoginBody, TermsFooter } from "./components";
import { loginUser } from "../../services/account";
import {
  setTokenToAxios,
  setTokenToLocalStorage,
  setUserRoleToLocalStorage,
} from "../../helpers/auth";
import { setCurrentUser } from "../../redux/actions/auth";

import { cl } from "./LoginPage.styles";
import { errorConfig } from "helpers/notifications";
import { useLogo } from "./useLogo";
import { toast } from "react-toastify";
import { useMediaDevice } from "hooks/useMediaDevice";

export const LoginPage = () => {
  const dispatch = useDispatch();
  const { isMobile } = useMediaDevice();
  const { distributor_name } = useParams();

  const { control, handleSubmit, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: { email: "", password: "", isRememberMe: true },
    resolver: yupResolver(validationSchema()),
  });

  // const [distributor, setDistributor] = useState({})

  const { logo, loadingImg } = useLogo({ distributor_name });

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const res = await loginUser(data);
        // Redirect to registration steps if a user has not confirmed a code
        if (!res?.appCustomer?.baseUser?.isConfirmed) {
          const searchParams = new URLSearchParams({
            tokenParam: res.token,
            stepParam: "code",
            distributorName: distributor_name,
            step: "2.1",
          });

          return navigate(`/registration/step-2?${searchParams.toString()}`);
        }

        // Redirect to the last registration step if is not completed
        if (!res.appCustomer?.orderDirect?.length) {
          const searchParams = new URLSearchParams({
            tokenParam: res.token,
            stepParam: "complete",
            distributorName:
              distributor_name ||
              res.appCustomer?.pendingRegistrationDistributor?.storeName,
          });

          return navigate(`/registration/step-3?${searchParams.toString()}`);
        }

        setTokenToLocalStorage(res.token);
        setUserRoleToLocalStorage("CUSTOMER");
        setTokenToAxios(res.token);
        dispatch(setCurrentUser(res.appCustomer));
        navigate("/");
      } catch (err) {
        toast.error(err?.response?.data?.message, errorConfig);
      }
      setIsLoading(false);
    },
    [dispatch, distributor_name, navigate]
  );

  return (
    <Box sx={cl.wrapper}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
        height="92vh"
      >
        <Paper sx={cl.paperWrapper} elevation={0} variant="outlined">
          {/* <LoginHeader /> */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              mt: { xs: 0, sm: "2vh" },
              mb: { xs: "32px", sm: "4vh" },
              gap: { xs: "24px", sm: 0 },
            }}
          >
            {loadingImg ? (
              <Box height="116px" display="flex" alignItems="center">
                <CircularProgress />
              </Box>
            ) : (
              logo
            )}
            <Typography
              fontSize={{ xs: "18px", sm: "clamp(14px, 2.5vh, 23px)" }}
              fontWeight={{ xs: 600, sm: 500 }}
              color={{ xs: "black", sm: "#5F6267" }}
            >
              Wholesale Login
            </Typography>
          </Box>
          <Box>
            <LoginBody
              setValue={setValue}
              isLoading={isLoading}
              control={control}
              onSubmit={handleSubmit(onSubmit)}
              distributorName={distributor_name}
            />
          </Box>
          {isMobile && <TermsFooter />}
          {!isMobile && <RegistrationBottomBlock />}
        </Paper>
      </Box>
      {!isMobile && <LoginFooter />}
    </Box>
  );
};

import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import lodash from "lodash";
import { DRAWERS } from "constants/drawer";
import { showCurrentDrawerAction } from "redux/actions/uiState";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { defaultValues } from "components/AddCardDialog/AddCardDialog.constants";
import { updateCustomerCardService } from "services/stripe";
import { error, success } from "helpers/notifications";
import { validationSchema } from "components/AddCardDialog/AddCardDialog.validations";
import { getFullAddressStripe } from "pages/CartPage/components/EditPaymentPopup/EditPaymentPopup.helpers";
import { getFormattedAddressInfo } from "helpers/helpers";
import { ConfirmCardDelete } from "components";
import { ConfirmContext } from "pages/MasterPage/MasterPage";

export const useEditCardDrawer = ({
  isOpen,
  customerId,
  card,
  handleSave,
  handleDeleteCard,
}) => {
  const dispatch = useDispatch();
  const { setConfirmState } = useContext(ConfirmContext);

  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues: {
      id: card?.id || "",
      fid: card?.fid || "",
      name: card?.name || card?.billingAddress?.name || defaultValues.name,
      expYear: card?.expYear || defaultValues.exp,
      expMonth: card?.expMonth || defaultValues.exp,
      billingAddress:
        {
          zip: card?.billingAddress?.address?.zip,
          street: card?.billingAddress?.address?.street,
          state: card?.billingAddress?.address?.state,
          city: card?.billingAddress?.address?.city,
          appartement: card?.billingAddress?.address?.appartement,
        } || defaultValues.billingAddress,
      defaultMethod: card?.defaultMethod || false,
    },
    resolver: yupResolver(
      validationSchema({ isEdit: true, editingAddress: false })
    ),
  });

  const formField = useWatch({ control });

  useEffect(() => {
    reset({
      id: card?.id || "",
      fid: card?.fid || "",
      name: card?.name || card?.billingAddress?.name || defaultValues.name,
      expYear: card?.expYear || defaultValues.exp,
      expMonth: card?.expMonth || defaultValues.exp,
      billingAddress:
        {
          zip: card?.billingAddress?.address?.zip,
          street: card?.billingAddress?.address?.street,
          state: card?.billingAddress?.address?.state,
          city: card?.billingAddress?.address?.city,
          appartement: card?.billingAddress?.address?.appartement,
        } || defaultValues.billingAddress,
      defaultMethod: card?.defaultMethod || false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, reset]);

  const onSubmit = (data) => {
    const addressFormatted = lodash.pickBy(
      {
        name: data?.name || card?.billingAddress?.name,
        defaultMethod: data?.defaultMethod || false,
        expMonth: data?.expMonth || card?.expMonth,
        expYear: data?.expYear || card?.expYear,
        number: card?.number || null,
        cvc: card?.cvc || null,
      },
      (value) => value !== null
    );
    const billingAddressFormatted = lodash.pickBy(
      {
        formatted_address:
          getFullAddressStripe(
            (!!data?.billingAddress?.city && data?.billingAddress) ||
              card?.billingAddress?.address
          ) || null,
        zip:
          (data?.billingAddress?.zip && data?.billingAddress?.zip) ||
          card?.billingAddress?.address?.zip ||
          card?.billingAddress?.address?.postal_code ||
          card?.billingAddress?.zip, // distributor
        city:
          (data?.billingAddress?.city && data?.billingAddress?.city) ||
          card?.billingAddress?.address?.city ||
          card?.billingAddress?.city || // distributor
          null,
        state:
          (data?.billingAddress?.state && data?.billingAddress?.state) ||
          card?.billingAddress?.address?.state ||
          card?.billingAddress?.state || // distributor
          null,
        street:
          (data?.billingAddress?.street && data?.billingAddress?.street) ||
          card?.billingAddress?.address?.street ||
          card?.billingAddress?.address?.line1 ||
          card?.billingAddress?.street || // distributor
          null,
        appartement:
          (data?.billingAddress?.appartement &&
            data?.billingAddress?.appartement) ||
          card?.billingAddress?.address?.appartement ||
          card?.billingAddress?.address?.line2 ||
          card?.billingAddress?.appartement || // distributor
          null,
        lat:
          data?.billingAddress?.lat || card?.billingAddress?.address?.lat || 0,
        lng:
          data?.billingAddress?.lng || card?.billingAddress?.address?.lng || 0,
      },
      (value) => value !== null
    );

    if (customerId) {
      setIsLoading(true);
      updateCustomerCardService(customerId, card?.id, {
        ...addressFormatted,
        billingAddress: billingAddressFormatted,
      })
        .then((res) => {
          handleSave(res?.rows, addressFormatted);
          success("Payment method updated");
          onClose();
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);
          const { error: errorMessage, message } = err?.response?.data || {};
          error(message || errorMessage);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const onClose = useCallback(
    () =>
      dispatch(
        showCurrentDrawerAction({
          type: DRAWERS.EDIT_CARD_DRAWER,
          show: false,
        })
      ),
    [dispatch]
  );

  const billingInfo = useMemo(
    () => getFormattedAddressInfo(formField?.billingAddress),
    [formField.billingAddress]
  );

  const FIELD_DATA = [
    {
      label: "Name on card",
      name: "name",
      placeholder: "Full Name",
    },
    {
      label: "Expiration Month",
      name: "expMonth",
      placeholder: "MM",
      items: lodash.range(1, 13),
      renderValue: (value) => (!value ? <span>MM</span> : value),
    },
    {
      label: "Expiration Year",
      name: "expYear",
      disabled: card?.hasExpiresDateIsDecline,
      placeholder: "YY",
      renderValue: (value) => (!value ? <span>YY</span> : value),
      items: lodash.range(
        new Date().getFullYear(),
        new Date().getFullYear() + 9
      ),
    },
  ];

  const onDelete = () => {
    setConfirmState((prev) => ({
      ...prev,
      onConfirm: () => {
        onClose();
        !!handleDeleteCard && handleDeleteCard(formField?.id);
      },
      title: "Delete payment method?",
      isOpen: true,
      cardStateItem: card,
      type: "drawer",
      titleElement: <ConfirmCardDelete card={card} />,
    }));
  };

  return {
    onClose,
    FIELD_DATA,
    control,
    handleSubmit,
    onSubmit,
    formField,
    isLoading,
    billingInfo,
    onDelete,
  };
};

import { func } from "prop-types";
import { Box, TextField, Typography } from "@mui/material";
import { cl } from "../../../../ViewOrderPage.styles";
import { useContext, useMemo } from "react";
import { OrderContext } from "pages/ViewOrderPage/ViewOrderPage.hooks";
import { StyledButton } from "components";
import moment from "moment-timezone";

export const FooterBlock = ({ handleUpdateNote }) => {
  const {
    FooterBlock: {
      wrapper,
      titleLeftSide,
      textNote,
      totalWrapper,
      textThin,
      text,
      label,
      divider,
    },
  } = cl;

  const { orderData, updateOrderData, currentUser } = useContext(OrderContext);

  const {
    totalQuantity,
    note,
    totalRawAmount,
    deliveryFee,
    totalDiscountValue,
    totalAmount,
    manufacturerDiscountDuplicates: MSD,
    orderPayments,
    totalRefundedPayments,
    fulfillBy,
  } = useMemo(() => orderData.order, [orderData.order]);

  const refunds = totalRefundedPayments || 0;

  const payments =
    orderPayments?.reduce((acc, cur) => {
      acc += cur?.amount;
      return acc;
    }, 0) || 0;

  const openBalance = totalAmount - payments + refunds || 0;
  const formattedDate = fulfillBy
    ? moment(fulfillBy).tz(currentUser?.timeZone).format("MMM D, YYYY")
    : null;

  return (
    <Box sx={wrapper}>
      <Box mt={0.8}>
        {note?.text && (
          <>
            <Box display="flex" alignItems="center" gap={2} height="30px">
              <Typography sx={titleLeftSide}>ORDER NOTES</Typography>
              {note?.text !== orderData?.editedNote && (
                <StyledButton
                  onClick={handleUpdateNote}
                  label="Update Note"
                  variant="contained"
                />
              )}
            </Box>
            <Box display="flex" pr={5} position="relative">
              <TextField
                placeholder="Add order note or delivery instructions"
                multiline
                rows={4}
                fullWidth
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                  style: textNote,
                }}
                value={orderData.editedNote}
                onChange={(e) =>
                  updateOrderData({ editedNote: e.target.value })
                }
              />
            </Box>
            <Box sx={{ ...divider, width: "100px" }} />
          </>
        )}

        <Typography sx={{ fontSize: "14px", mt: "15px" }}>
          Fulfill By:
        </Typography>
        <Typography sx={{ fontSize: "13px", color: "#7F7F7F" }}>
          {formattedDate || "-"}
        </Typography>
      </Box>
      <Box sx={totalWrapper}>
        <Box display="flex" justifyContent="space-between">
          <Typography sx={textThin}>Cases ({totalQuantity}):</Typography>
          <Typography sx={text}>{totalRawAmount.toFixed(2)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography sx={textThin}>Delivery:</Typography>
          <Typography sx={text}>
            {deliveryFee ? `$${deliveryFee?.toFixed(2)}` : "FREE"}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography sx={textThin}>Discounts:</Typography>
          <Typography sx={text}>-${totalDiscountValue.toFixed(2)}</Typography>
        </Box>
        {!!MSD?.length && (
          <Box display="flex" justifyContent="flex-start" gap={2.5}>
            {MSD.map(({ id, name }) => (
              <Box key={id} sx={label} component={Typography}>
                {name}
              </Box>
            ))}
          </Box>
        )}
        <Box sx={divider} />
        <Box display="flex" justifyContent="space-between">
          <Typography sx={text}>Grand Total</Typography>
          <Typography sx={{ ...text, color: "#47A06D", fontWeight: "600" }}>
            $ {totalAmount.toFixed(2)}
          </Typography>
        </Box>
        {payments > 0 && (
          <>
            <Box sx={divider} />
            <Box display="flex" justifyContent="space-between">
              <Typography sx={text}>Payments</Typography>
              <Typography sx={text}>- {payments.toFixed(2)}</Typography>
            </Box>
          </>
        )}
        {openBalance > 0 && (refunds > 0 || payments > 0) && (
          <>
            <Box sx={divider} />
            <Box display="flex" justifyContent="space-between">
              <Typography sx={text}>Open balance</Typography>
              <Typography sx={{ ...text, color: "#47A06D", fontWeight: "600" }}>
                $ {openBalance.toFixed(2)}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

FooterBlock.propTypes = {
  handleUpdateNote: func,
};

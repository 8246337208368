import { yupResolver } from "@hookform/resolvers/yup";
import { matchEmail } from "helpers/helpers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { contactVendorAction } from "redux/actions/distributors";
import { validationSchema } from "./ContactTab.validations";
import { defaultValues } from "./ContactTab.constants";
import { useCartCounter } from "pages/ProductsPage/useCartCounter";
import { StyledTextField } from "components";
import { success } from "helpers/notifications";
import { useMediaDevice } from "hooks/useMediaDevice";

export const useContactSupplier = (
  supplier,
  onCloseDialog = () => {},
  open = false
) => {
  const currentUser = useSelector(({ auth }) => auth.currentUser);
  const { isMobile } = useMediaDevice();

  const { currentOrderDirect, handleGetOrderDirectById } = useCartCounter();

  const orderDirect = currentOrderDirect?.[0]?.distributor || {};
  const inputRef = useRef();

  const orderDirectData = orderDirect?.id
    ? orderDirect
    : handleGetOrderDirectById(supplier.id)?.distributor;

  const { id, baseUser } = orderDirectData || {};

  const [recipientsSearch, setRecipientsSearch] = useState("");
  const [CCList, setCCList] = useState([]);
  const [sent, setSent] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const attachmentsSizeExceeded = useMemo(
    () =>
      attachments.reduce(
        (prevSum, attachment) => prevSum + attachment.size,
        0
      ) > 10485760, //10 MB limit in bytes
    [attachments]
  );

  const { control, handleSubmit, reset, setValue, setError, clearErrors } =
    useForm({
      mode: "onChange",
      defaultValues: { ...defaultValues },
      resolver: yupResolver(validationSchema()),
    });

  const formField = useWatch({ control });

  useEffect(() => {
    reset({ ...defaultValues });
  }, [reset, supplier]);

  useEffect(() => {
    if (!id) return;
    const isAlreadyAdded = formField?.cc?.some((c) => c?.id === id);
    const isAlreadyAddedToCC = CCList?.some((c) => c?.id === id);

    if (!isAlreadyAddedToCC)
      setCCList([
        ...CCList,
        {
          id: id,
          email: baseUser?.email,
        },
      ]);

    if (isAlreadyAdded) return;

    setValue("cc", [
      ...formField.cc,
      {
        id: id,
        email: baseUser?.email,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, baseUser, id]);

  useEffect(() => {
    setCCList(
      currentUser.contacts.filter(
        (c) =>
          c.email.includes(recipientsSearch) ||
          c.name.includes(recipientsSearch)
      )
    );
  }, [recipientsSearch, currentUser]);

  useEffect(() => {
    if (!formField.cc.some((c) => c.id === currentUser.id))
      setValue("sendMe", false);
  }, [formField.cc, setValue, currentUser.id]);

  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    setSent(true);
    !!onCloseDialog && onCloseDialog();
    isMobile && success("Message sent");
  }, [isMobile, onCloseDialog]);

  const onSubmit = useCallback(
    (data) => {
      if (recipientsSearch) {
        return setError("cc", {
          message: "Please add a valid email address.",
        });
      }

      const recipients = data.cc
        .filter((c) => c.id !== currentUser.id)
        .map((contact) => contact.email.trim());

      const { cc, files, ...uploadData } = {
        ...data,
        recipients,
      };

      const uploadFormData = new FormData();

      Object.keys(uploadData).forEach((key) =>
        uploadFormData.append(key, JSON.stringify(uploadData[key]))
      );

      const filesArray = Object.keys(data.files).map((f) => data.files[f]);

      dispatch(
        contactVendorAction({
          distributorId: supplier.id,
          data: uploadFormData,
          files: filesArray,
          onSuccess: () => {
            onClose();
            reset({ ...defaultValues });
          },
        })
      );
    },
    [
      recipientsSearch,
      dispatch,
      supplier.id,
      setError,
      currentUser.id,
      onClose,
      reset,
    ]
  );

  const handleRemoveFile = (fileName) => {
    setAttachments((prev) => [...prev.filter((f) => f.name !== fileName)]);
    const files = formField.files;
    delete files[fileName];
    setValue("files", files);
  };

  const onAttach = (e) => {
    const files = Array.prototype.slice.call(e.target.files);
    const objFiles = [];
    const cachedURL = URL.createObjectURL(e.target.files[0]);

    const filesList = formField.files;
    files.forEach((f) => (filesList[f.name] = f));
    files.forEach((file) => {
      objFiles.push({
        size: file.size,
        name: file.name,
        type: file.type,
        url: cachedURL,
      });
    });
    setAttachments([...attachments, ...objFiles]);
    setValue("files", filesList);
  };
  const processingIntoChip = useCallback(() => {
    if (recipientsSearch && matchEmail(recipientsSearch)) {
      setRecipientsSearch("");
      setValue("cc", [
        ...formField.cc,
        {
          id: recipientsSearch,
          email: recipientsSearch,
        },
      ]);
      clearErrors("cc");
    }
  }, [clearErrors, formField.cc, recipientsSearch, setValue]);

  const handlePressSpace = useCallback(
    (e) => {
      const val = e.target.value;
      if (e.keyCode === 32 && val !== "") {
        processingIntoChip();
      }
    },
    [processingIntoChip]
  );

  const hasSelectedDistrib = useMemo(
    () => (id ? formField?.cc?.some((c) => c?.id === id) : true),
    [formField.cc, id]
  );

  const disabledSubmitBtn = useMemo(
    () =>
      !formField.message.trim() ||
      !formField.subject ||
      !formField.cc.length ||
      !hasSelectedDistrib ||
      recipientsSearch.trim() !== "",
    [
      formField.cc.length,
      formField.message,
      formField.subject,
      recipientsSearch,
      hasSelectedDistrib,
    ]
  );

  const FIELD_DATA = useMemo(() => {
    return [
      {
        label: "Subject:",
        name: "subject",
        placeholder: "Subject",
        Input: StyledTextField,
      },
      {
        label: "Message:",
        name: "message",
        placeholder: "Example of the message",
        simpleStyles: true,
        rows: 7,
        multiline: true,
        Input: StyledTextField,
      },
    ];
  }, []);

  const distribIsAlreadyAdded = useMemo(
    () =>
      !!formField?.cc?.find((e) => e?.email === currentUser?.baseUser?.email),
    [currentUser?.baseUser?.email, formField?.cc]
  );

  return {
    disabledSubmitBtn,
    onSubmit,
    formField,
    control,
    setValue,
    handleSubmit,
    attachments,
    onAttach,
    handleRemoveFile,
    setRecipientsSearch,
    recipientsSearch,
    handlePressSpace,
    inputRef,
    CCList,
    sent,
    attachmentsSizeExceeded,
    processingIntoChip,
    currentUser,
    FIELD_DATA,
    baseUser,
    distribIsAlreadyAdded,
  };
};

import React, { useState, useCallback, useEffect, useRef } from "react";
import { string, node, number, bool, object } from "prop-types";

import { Box } from "@mui/material";
import useStyles from "./styles";

const SCROLL_BOX_MIN_HEIGHT = 20;

export const ScrollControlWrapper = ({
  children,
  dataLength = null,
  maxHeight = "100%",
  id = "",
  documentId = "",
  scrollBarStyle = {},
  ...restProps
}) => {
  const classes = useStyles({ maxHeight, scrollBarStyle });

  const scrollHostRef = useRef();
  const contentWrapper = document.getElementById(
    documentId || "content-wrapper"
  );

  const scrollHostElement =
    id && scrollHostRef ? scrollHostRef.current : contentWrapper;

  // const [hovering, setHovering] = useState(false);
  const [isDragging, setDragging] = useState(false);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [scrollBoxTop, setScrollBoxTop] = useState(0);
  const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0);
  const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT);

  const handleDocumentMouseUp = useCallback(
    (e) => {
      if (isDragging) {
        e.preventDefault();
        setDragging(false);
      }
    },
    [isDragging]
  );

  const handleDocumentMouseMove = useCallback(
    (e) => {
      if (!scrollHostElement) return;
      if (isDragging) {
        e.preventDefault();
        e.stopPropagation();

        const { scrollHeight, offsetHeight } = scrollHostElement;

        let deltaY = e.clientY - lastScrollThumbPosition;
        let percentage = deltaY * (scrollHeight / offsetHeight);

        setScrollThumbPosition(e.clientY);
        setScrollBoxTop(
          Math.min(
            Math.max(0, scrollBoxTop + deltaY),
            offsetHeight - scrollBoxHeight
          )
        );
        scrollHostElement.scrollTop = Math.min(
          scrollHostElement.scrollTop + percentage,
          scrollHeight - offsetHeight
        );
      }
    },
    [
      isDragging,
      lastScrollThumbPosition,
      scrollBoxHeight,
      scrollBoxTop,
      scrollHostElement,
    ]
  );

  const handleScrollThumbMouseDown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setScrollThumbPosition(e.clientY);
    setDragging(true);
  }, []);

  const handleScroll = useCallback(() => {
    if (!scrollHostRef || !scrollHostElement) {
      return;
    }
    const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement;

    let newTop =
      (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) * offsetHeight;
    newTop = Math.min(newTop, offsetHeight - scrollBoxHeight);
    setScrollBoxTop(newTop);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollBoxHeight, dataLength, scrollHostElement]);

  useEffect(() => {
    if (!scrollHostElement) return;

    const { clientHeight, scrollHeight } = scrollHostElement;

    if (!(clientHeight > 0 && scrollHeight > 0)) return;

    const scrollThumbPercentage = clientHeight / scrollHeight;
    const scrollThumbHeight = Math.max(
      scrollThumbPercentage * clientHeight,
      SCROLL_BOX_MIN_HEIGHT
    );
    setScrollBoxHeight(scrollThumbHeight);
    handleScroll();
  }, [handleScroll, dataLength, scrollHostElement, maxHeight]);

  useEffect(() => {
    if (!scrollHostElement) return;

    const { clientHeight, scrollHeight } = scrollHostElement;
    setShowScrollbar(scrollHeight > clientHeight);
  }, [scrollHostElement]);

  useEffect(() => {
    if (!scrollHostElement) return;

    scrollHostElement.addEventListener("scroll", handleScroll, true);
    return function cleanup() {
      scrollHostElement.removeEventListener("scroll", handleScroll, true);
    };
  }, [handleScroll, scrollHostElement]);

  useEffect(() => {
    document.addEventListener("mousemove", handleDocumentMouseMove);
    document.addEventListener("mouseup", handleDocumentMouseUp);
    document.addEventListener("mouseleave", handleDocumentMouseUp);
    return function cleanup() {
      document.removeEventListener("mousemove", handleDocumentMouseMove);
      document.removeEventListener("mouseup", handleDocumentMouseUp);
      document.removeEventListener("mouseleave", handleDocumentMouseUp);
    };
  }, [handleDocumentMouseMove, handleDocumentMouseUp]);

  return (
    <Box className={classes.scrollhostContainer}>
      <Box
        className={classes.scrollhost}
        id={id}
        ref={scrollHostRef}
        {...restProps}
      >
        {children}
      </Box>
      {showScrollbar && (
        <Box className={classes.scrollBar}>
          <Box
            className={classes.scrollThumb}
            style={{ height: scrollBoxHeight, top: scrollBoxTop }}
            onMouseDown={handleScrollThumbMouseDown}
          />
        </Box>
      )}
    </Box>
  );
};

export default ScrollControlWrapper;

ScrollControlWrapper.propTypes = {
  children: node,
  dataLength: number || null,
  loading: bool,
  maxHeight: string,
  id: string,
  documentId: string,
  scrollBarStyle: object,
};

import { useContext, useEffect, useState, useMemo, useRef } from "react";
import { Controller, useWatch } from "react-hook-form";
import NumberFormat from "react-number-format";

import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Context } from "../RegistrationBody/RegistrationBody";
import {
  AddressField,
  MapPopup,
  SmallCheckbox,
  StyledTextField,
} from "../../../../components";

import { cl } from "../../RegistrationPage.styles";
import { cl as loginCl } from "../../../LoginPage/LoginPage.styles";
import useStyles from "./styles";
import { ExpandMore } from "@mui/icons-material";
import { CANADA_PHONE_CODES, COUNTRY_PHONE_CODES } from "utils/constants";
import { PlaceAutocompleteField } from "components/PlaceAutocompleteField/PlaceAutocompleteField";
import { resendRegistrationCodeService } from "services/account";
import { error, success } from "helpers/notifications";
import { getAddressComponents } from "components/AddressField/AddressField.helpers";
import { onPastePhone, sliceCountry } from "helpers/helpers";
import { useMediaDevice } from "hooks/useMediaDevice";
import { StyledArrowIcon } from "components/Icons";
import { LabeledTextField } from "components/TextFields/TextFields";

export const StepTwo = () => {
  const { isMobile } = useMediaDevice();
  const classes = useStyles();
  const nameRef = useRef(null);

  const {
    control,
    setValue,
    errors,
    isLoading,
    errPhoneStatus,
    step,
    token,
    clearErrors,
    trigger,
  } = useContext(Context);

  const formField = useWatch({ control });

  const StyledInput = isMobile ? LabeledTextField : StyledTextField;

  const userCountryPhoneCodes =
    formField.country === "CA" ? CANADA_PHONE_CODES : COUNTRY_PHONE_CODES;

  const [mapOpen, setMapOpen] = useState(null);
  const [toggleDetails, setToggleDetails] = useState("");

  useEffect(() => {
    if (formField.sameAsBillingAddress) {
      setValue("shippingAddress", formField.billingAddress);
      clearErrors("shippingAddress");
    }
  }, [
    formField.billingAddress,
    formField.billingAddress.zip,
    formField.billingAddress.city,
    formField.billingAddress.formatted_address,
    formField.billingAddress.street,
    formField.billingAddress.state,
    formField.sameAsBillingAddress,
    setValue,
    clearErrors,
  ]);

  const getErrorMessage = useMemo(() => {
    if (Object.entries(errors)?.[0]?.[1]?.message)
      return Object.entries(errors)?.[0]?.[1]?.message;
    if (Object.entries(errors)?.[0]?.[0] === "billingAddress")
      return "Billing address is incorrect!";
    if (Object.entries(errors)?.[0]?.[0] === "shippingAddress")
      return "Shipping address is incorrect!";
    return;
  }, [errors]);

  const preparedNumber = (n) => {
    if (!n) return "";
    if (n.startsWith("(") && n.length === 14)
      return n.replace(/\s|\(|\)|-/g, "");
    return n;
  };

  const handleSetCustomer = (customerInfo) => {
    const { street, state, city, zip } = getAddressComponents(customerInfo);

    setValue("name", customerInfo.name);
    setValue(
      "businessPhone",
      preparedNumber(customerInfo.formatted_phone_number) || ""
    );
    // setValue("website", customerInfo.website || "");
    setValue("billingAddress", {
      formatted_address: sliceCountry(customerInfo.formatted_address),
      lat: customerInfo.geometry.location.lat(),
      lng: customerInfo.geometry.location.lng(),
      street,
      state,
      city,
      zip,
    });
  };

  const handlerResendRegistrationCode = () => {
    resendRegistrationCodeService({
      token: token || formField?.token || formField?.regParams?.tokenParam,
      params: { distributor_id: formField?.distributorData?.id },
    })
      .then((res) => {
        if (res?.status === 200) success("The code was sent");
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err.message);
        error(err?.response?.data?.message);
      });
  };

  const selectProps = isMobile
    ? {
        sx: cl.select,
        IconComponent: StyledArrowIcon,
        labelSx: cl.mobileLabelSx,
        displayEmpty: true,
        height: "48px",
        renderValue: (s) => (s ? s : <span>State</span>),
      }
    : cl.selectProps;

  const autocompleteBillingProps = {
    size: "small",
    noErrorMessage:
      !errors?.billingAddress?.lat || !errors?.billingAddress?.lng,
    InputProps: {
      ...(!isMobile
        ? {
            className: classes.input,
            placeholder: "",
            sx: {
              "& input": { animationName: "none !important" },
            },
          }
        : {
            sx: {
              ...loginCl.inputSx,
              ...(!!errors.billingAddress && loginCl.errorInputSx),
            },
            placeholder: "Billing address",
          }),
    },
    InputLabelProps: {
      size: "small",
      className: classes.inputLabel,
    },
  };

  const autocompleteShippingProps = {
    ...autocompleteBillingProps,
    InputProps: {
      ...(!isMobile
        ? {
            className: classes.input,
            placeholder: "",
            sx: {
              "& input": { animationName: "none !important" },
            },
          }
        : {
            sx: {
              ...loginCl.inputSx,
              ...(!!errors.shippingAddress && loginCl.errorInputSx),
            },
            placeholder: "Shipping address",
          }),
    },
  };

  const resetBillingAddressCoordinates = () => {
    setValue("billingAddress.lat", null);
    setValue("billingAddress.lng", null);
  };

  const changeCurtainMarginBillingAddress = useMemo(
    () => !!errors?.billingAddress?.lat || !!errors?.billingAddress?.lng,
    [errors.billingAddress]
  );

  const changeCurtainMarginShippingAddress = useMemo(
    () => !!errors?.shippingAddress?.lat || !!errors?.shippingAddress?.lng,
    [errors.shippingAddress]
  );

  return (
    <>
      {step === 2 && (
        <>
          <MapPopup
            handleSetAddress={(address) => setValue(mapOpen, address)}
            address={formField[mapOpen]}
            isOpen={!!mapOpen}
            customer={formField}
            handleClose={() => setMapOpen(null)}
          />
          <Controller
            render={({ field, fieldState: { error } }) => (
              <PlaceAutocompleteField
                country={formField.country}
                size="small"
                autoComplete="new-password"
                paperstyles={{ top: 46 }}
                sx={{ borderRadius: "8px" }}
                InputProps={{
                  height: "38px",
                  fontSize: "15px",
                  "& input": { padding: "7px 14px" },
                  "&:hover:not(.Mui-focused)": {
                    "&& fieldset": {
                      borderColor: "rgba(194, 194, 194, 0.5)",
                    },
                  },
                  ...(isMobile && {
                    placeholder: "Legal business name",
                    sx: {
                      ...loginCl.inputSx,
                      ...(error && loginCl.errorInputSx),
                    },
                  }),
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "15px",
                    fontWeight: 300,
                    color: "#B5B5AC",
                  },
                }}
                formSx={{
                  fontSize: 15,
                  height: { xs: "48px", sm: "38px" },
                  "& .MuiOutlinedInput-notchedOutline": {
                    fontSize: "15px",
                  },
                }}
                handleSetCustomer={handleSetCustomer}
                error={error ? error.message : ""}
                label={isMobile ? "" : "Legal business name"}
                noErrorMessage
                setCustomerLoading={() => {}}
                {...field}
                ref={nameRef}
                value={field.value}
              />
            )}
            name="name"
            control={control}
          />
          <AddressField
            fieldName="billingAddress"
            label={isMobile ? "" : "Billing address"}
            country={formField.country || "US"}
            control={control}
            setValue={setValue}
            address={formField.billingAddress}
            curtainProps={{
              curtainHeight: isMobile ? "221px" : "250px",
              marginTop: changeCurtainMarginBillingAddress && "8px",
            }}
            error={!!errors.billingAddress}
            clearErrors={clearErrors}
            typingTrigger={resetBillingAddressCoordinates}
            errorCoordinates={
              !!errors?.billingAddress?.lat || !!errors?.billingAddress?.lng
            }
            addressToggled={toggleDetails === "billingAddress"}
            addressFieldsWrapperProps={cl.addressFieldsWrapperProps}
            // eslint-disable-next-line no-undef
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            onToggle={() => {
              if (toggleDetails === "billingAddress")
                return setToggleDetails("");
              setToggleDetails("billingAddress");
            }}
            toggleButtonProps={{ sx: cl.toggleBtnSx }}
            selectProps={selectProps}
            textFieldProps={{
              onBlur: () => trigger("billingAddress"),
              InputProps: {
                size: "small",
                className: classes.input,
              },
              size: "small",
              InputLabelProps: {
                className: classes.inputLabel,
              },
            }}
            noToggleLabel
            hideUnit
            autocompleteProps={autocompleteBillingProps}
          />
          <AddressField
            disabled={!!formField.sameAsBillingAddress}
            fieldName="shippingAddress"
            label={isMobile ? "" : "Shipping address"}
            country={formField.country || "US"}
            control={control}
            setValue={setValue}
            address={formField.shippingAddress}
            curtainProps={{
              curtainHeight: isMobile ? "221px" : "250px",
              marginTop: changeCurtainMarginShippingAddress && "8px",
            }}
            error={!!errors.shippingAddress}
            addressFieldsWrapperProps={cl.addressFieldsWrapperProps}
            addressToggled={toggleDetails === "shippingAddress"}
            // eslint-disable-next-line no-undef
            apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
            onToggle={() => {
              if (toggleDetails === "shippingAddress")
                return setToggleDetails("");
              setToggleDetails("shippingAddress");
            }}
            toggleButtonProps={{ sx: cl.toggleBtnSx }}
            selectProps={selectProps}
            textFieldProps={{
              InputProps: {
                size: "small",
                className: classes.input,
              },
              size: "small",
              InputLabelProps: {
                className: classes.inputLabel,
              },
            }}
            noToggleLabel
            hideUnit
            autocompleteProps={autocompleteShippingProps}
          />
          <Box
            sx={{
              mb: { xs: -1.1, sm: -1.5 },
              mt: { xs: -1.1, sm: -1.5 },
              display: "flex",
            }}
          >
            <Controller
              render={({ field }) => (
                <SmallCheckbox
                  width="20"
                  height="20"
                  formSx={{ ml: "-9px" }}
                  checked={!!field.value}
                  label={
                    <Typography
                      sx={{ color: "#000" }}
                      fontSize="clamp(10px, 2vh, 18px)"
                      fontWeight={{ xs: 400, sm: 300 }}
                    >
                      same as billing address
                    </Typography>
                  }
                  {...field}
                />
              )}
              name="sameAsBillingAddress"
              control={control}
            />
          </Box>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <NumberFormat
                customInput={StyledTextField}
                fullWidth
                format={
                  isMobile
                    ? "+1 (###) ### ####"
                    : formField?.countryPrefix?.format
                }
                mask="_"
                size="small"
                InputProps={{
                  ...(isMobile && {
                    sx: {
                      ...loginCl.inputSx,
                      ...(!!error && loginCl.errorInputSx),
                    },
                    placeholder: "Business phone",
                  }),
                  style: {
                    height: { xs: "48px", sm: "38px" },
                    fontSize: "15px",
                    paddingLeft: 0,
                  },
                  ...(!isMobile && {
                    startAdornment: (
                      <FormControl>
                        <Controller
                          render={({ field }) => {
                            return (
                              <Select
                                sx={cl.desktopPhoneSelect}
                                size="small"
                                renderValue={() => field?.value?.phone_code}
                                IconComponent={(props) => (
                                  <ExpandMore
                                    sx={{ fontSize: 20 }}
                                    {...props}
                                  />
                                )}
                                {...field}
                                value={field?.value}
                              >
                                {userCountryPhoneCodes.map((code) => {
                                  return (
                                    <MenuItem key={code.label} value={code}>
                                      {code.phone_code} {code.label}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            );
                          }}
                          name="countryPrefix"
                          control={control}
                        />
                      </FormControl>
                    ),
                  }),
                }}
                onClick={() => {
                  if (["", "+"].includes(field?.value) && isMobile) {
                    setValue("businessPhone", "+1");
                  }
                }}
                error={
                  errPhoneStatus.status
                    ? errPhoneStatus.message
                    : error?.message || ""
                }
                {...field}
                autoComplete="new-password"
                onPaste={(e) => {
                  const prepareVal = (v) => {
                    if (/^\+[\d]+/.test(v)) {
                      return v.substring(2);
                    }
                    return v;
                  };

                  onPastePhone({
                    e,
                    type: "businessPhone",
                    setValue: (type, val) => {
                      setValue(type, prepareVal(val));
                    },
                    currentValue: formField?.businessPhone,
                  });
                }}
              />
            )}
            name="businessPhone"
            control={control}
          />
          {!isMobile && (
            <Box my={-1} display="flex" height="20px" gap={1}>
              <Typography sx={{ color: "#FF3737" }} fontSize={11}>
                {getErrorMessage}
              </Typography>
            </Box>
          )}
          <Button
            sx={{ ...loginCl.loginBtn, mt: { xs: "32px", sm: "0px" } }}
            variant="contained"
            form="registration-form"
            type="submit"
          >
            {isLoading ? "Loading..." : "Create Free Account"}
          </Button>
        </>
      )}

      {step === 2.1 && (
        <>
          <Stack gap={{ xs: "2px", sm: "20px" }}>
            <Box textAlign="center">
              <Typography
                sx={{ color: "#5F6267" }}
                fontSize={{ xs: "18px", sm: "clamp(17px, 2.5vh, 25px)" }}
                fontWeight={{ xs: 500, sm: 600 }}
              >
                We sent a code to your email
              </Typography>
            </Box>
            <Box textAlign="center" mt={{ xs: 0, sm: -2.5 }}>
              <Typography
                sx={{ color: { xs: "#00000060", sm: "#1C1C19" } }}
                fontSize={{ xs: "14px", sm: "clamp(10px, 1.8vh, 15px)" }}
                fontWeight={{ xs: 400, sm: 300 }}
              >
                Enter One Time Password (OTP) sent to your email.
              </Typography>
            </Box>
            <Box
              textAlign="center"
              mt={{ xs: 0, sm: -2 }}
              mb={{ xs: "24px", sm: 0 }}
            >
              <Typography fontSize={15} fontWeight={300} color="primary" noWrap>
                {formField?.businessEmail}
              </Typography>
            </Box>
          </Stack>
          <Controller
            render={({ field, fieldState: { error } }) => (
              <StyledInput
                sx={{ mt: 0 }}
                inputSx={{ ...cl.inputSx }}
                labelSx={cl.labelSx}
                label="6 digit code"
                size="small"
                inputProps={{ placeholder: "6 digit code" }}
                error={error?.message || ""}
                {...field}
              />
            )}
            name="code"
            control={control}
          />
          {!isMobile && (
            <Box my={-1} display="flex" height="20px" gap={1}>
              <Typography sx={{ color: "#FF3737" }} fontSize={11}>
                {Object.entries(errors)?.[0]?.[1]?.message}
              </Typography>
            </Box>
          )}
          <Button
            sx={{
              ...loginCl.loginBtn,
              mt: { xs: "12px", sm: "0px" },
              mb: { xs: 0, sm: -1 },
            }}
            variant="contained"
            form="registration-form"
            type="submit"
          >
            {isLoading ? "Loading..." : "Create Free Account"}
          </Button>
          <Button
            sx={{ ...loginCl.createBtn, mt: { xs: "26px", sm: 0 } }}
            variant="cancel"
            onClick={handlerResendRegistrationCode}
          >
            Resend Code
          </Button>
          <Box
            sx={{
              mt: { xs: "22px", sm: -2.4 },
              textAlign: "center",
            }}
          >
            <Typography
              sx={{ color: "#7E7E7E" }}
              fontSize="clamp(10px, 1.8vh, 15px)"
              fontWeight={{ xs: 400, sm: 300 }}
            >
              If you don&apos;t see the email in your inbox,
              <br />
              check your spam folder.
            </Typography>
          </Box>
        </>
      )}
    </>
  );
};

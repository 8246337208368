import { object, func, bool, number } from "prop-types";

import { ActionsBlock } from "./components/ActionsBlock";
import { PictureBlock } from "../ProductCard/components";
import { ProductQtySelect } from "./components/ProductQtySelect";
import { ProductNameBlock } from "./components/ProductNameBlock";

import { cl } from "./styles";

import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

const MobileProductCard = ({
  product,
  supplierApproved,
  showRetailPrice,
  isChild = false,
  parentProduct = {},
  handleAddToCart,
  handleIsAlreadyAdded,
  handleGetCurrentDraft,
  getDefaultProductValue,
  handleGetQTYFromDraft,
  isShowProductsWithoutLogIn,
  handleOpenProductProfile,
  cardSx = {},
}) => {
  const [productQty, setProductQty] = useState(0);

  const {
    id,
    sku,
    name,
    description,
    size,
    color,
    sellingOutOfStock,
    minOrderQTY,
  } = product || {};

  const currentProduct = useMemo(
    () =>
      isChild
        ? {
            ...product,
            distributor: parentProduct.distributor,
            sellingOutOfStock: parentProduct.sellingOutOfStock,
          }
        : product,
    [
      isChild,
      parentProduct?.distributor,
      parentProduct?.sellingOutOfStock,
      product,
    ]
  );

  const isAdded = useMemo(
    () => handleIsAlreadyAdded(currentProduct),
    [handleIsAlreadyAdded, currentProduct]
  );

  const currentDraft = handleGetCurrentDraft(
    product?.distributor?.id || parentProduct?.distributor?.id
  );

  const draftQTY = isAdded ? handleGetQTYFromDraft(currentDraft, id) : 0;

  const isAlreadyChangedAndAdded =
    isAdded && draftQTY && productQty !== draftQTY;

  const productMinOrderQTY = isChild ? parentProduct.minOrderQTY : minOrderQTY;
  const productName = isChild ? parentProduct.name : name;
  const productDescription = isChild ? parentProduct.description : description;
  const productSellingOutOfStock = isChild
    ? parentProduct?.sellingOutOfStock
    : sellingOutOfStock;

  const { onHand, allocated } = currentProduct.inventory || {};

  const getAvailableQTY = onHand - allocated;

  const isLowerThanMOQ = useMemo(
    () =>
      !productSellingOutOfStock &&
      productMinOrderQTY &&
      currentProduct.type === PRODUCT_TYPE_INVENTORY.inventory &&
      getAvailableQTY < productMinOrderQTY,
    [
      currentProduct.type,
      getAvailableQTY,
      productMinOrderQTY,
      productSellingOutOfStock,
    ]
  );

  useEffect(() => {
    const defaultValue = getDefaultProductValue(currentProduct);
    const preparedDefaultMinOrderQTY = isLowerThanMOQ ? 0 : productMinOrderQTY;

    setProductQty(defaultValue > 0 ? defaultValue : preparedDefaultMinOrderQTY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdded, getDefaultProductValue, currentProduct]);

  const showMOQTooltip = productMinOrderQTY
    ? !!productQty && +productQty < productMinOrderQTY
    : productQty && +productQty < 0;

  const showStockTooltip =
    currentProduct.type === PRODUCT_TYPE_INVENTORY.inventory &&
    !productSellingOutOfStock &&
    productQty > getAvailableQTY;

  return (
    <Card sx={{ ...cl.cardWrapper, ...cardSx }}>
      <PictureBlock
        photos={product?.photos}
        height="167px"
        mediaSx={{ backgroundSize: "contain", backgroundColor: "#ffff" }}
        product={product}
        handleOpenProductProfile={handleOpenProductProfile}
      />

      <CardContent sx={cl.cardContent}>
        <ProductNameBlock {...{ size, color, sku, productName }} />

        {productDescription && (
          <Box sx={cl.productDescriptionWrapper}>
            <Typography sx={cl.productDescription}>
              {productDescription}
            </Typography>
          </Box>
        )}

        <Stack mt="auto" gap="8px">
          {!isShowProductsWithoutLogIn && supplierApproved && (
            <ProductQtySelect
              {...{
                isLowerThanMOQ,
                product: currentProduct,
                currentDraftProducts: currentDraft?.data?.productsData,
                productQty,
                setProductQty,
                productSellingOutOfStock,
                isAdded,
                minOrderQTY: productMinOrderQTY,
                showMOQTooltip: !!showMOQTooltip,
                showStockTooltip,
              }}
            />
          )}
          <ActionsBlock
            {...{
              product: currentProduct,
              isAlreadyChangedAndAdded,
              showRetailPrice,
              supplierApproved,
              productQty,
              handleAddToCart,
              isAdded,
              isShowProductsWithoutLogIn,
              disabledAdding: !!showMOQTooltip || !!showStockTooltip,
            }}
          />
        </Stack>
      </CardContent>
    </Card>
  );
};

MobileProductCard.propTypes = {
  product: object,
  cardSx: object,
  parentProduct: object,
  suppliersCount: number,
  handleOpenProductProfile: func,
  handleGetQTYFromDraft: func,
  filteredBySupplier: bool,
  supplierApproved: bool,
  showRetailPrice: bool,
  isChild: bool,
  isShowProductsWithoutLogIn: bool,
  handleAddToCart: func,
  handleIsAlreadyAdded: func,
  handleGetCurrentDraft: func,
  getDefaultProductValue: func,
};

export default MobileProductCard;

import { useContext, useEffect } from "react";

import {
  AddCardDrawer,
  ContactDrawer,
  EditCardDrawer,
  NoteDrawer,
} from "components";
import { useCustomerCards } from "components/CustomerCards/CustomerCards.hooks";
import { SummaryProgress } from "../OrdersListBlock/components/CartOrderItem/components/AccSummaryBlock/components";
import {
  CartActionBlock,
  DeliveryCartBlock,
  OptionsBlock,
  ProductsList,
  TotalCartBlock,
} from "./components";

import { CartContext } from "pages/CartPage/CartPage";

import { cl } from "./styles";

import { Stack } from "@mui/material";
import { SelectCardDrawer } from "components/SelectCardDrawer/SelectCardDrawer";
import { ErrorsBlock } from "./components/ErrorsBlock/ErrorsBlock";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useNavigate } from "react-router-dom";
import { UnavailableProductDialog } from "..";

export const MobileCartPage = () => {
  const {
    draftsLoading,
    setValue,
    handleAddNewContact,
    onDeleteContact,
    contacts,
    customerId,
    selectedContact,
    cartData,
    removeDraftCard,
    formField,
    handleSetCardToCart,
    calculatedTotal,
    orderDirect,
    errors,
    products: productsList,
    userProgressStep,
    calculatedDelivery,
    customerDiscount,
    grandTotalWithDiscount,
    casesCount,
    updateCartData,
    clearErrors,
    resetCardCart,
    unavailableProductDialogState,
    handleRemoveUnavailableProducts,
  } = useContext(CartContext);

  const navigate = useNavigate();

  const { selectedSupplier, hasSelectedSupplier, hasMultipleSuppliers } =
    useCurrentUser();

  useEffect(() => {
    if (hasSelectedSupplier && !cartData?.selectedDistributor) {
      updateCartData({ selectedDistributor: selectedSupplier });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cartData?.selectedDistributor,
    hasSelectedSupplier,
    navigate,
    selectedSupplier,
  ]);

  const onlyApproved = ({ status }) => status === "APPROVED";

  const approvedDist =
    hasMultipleSuppliers && hasSelectedSupplier
      ? selectedSupplier
      : orderDirect.filter(onlyApproved)?.[0]?.distributor || {};

  const {
    cardForEdit,
    setCardForEdit,
    handleFetchCards,
    creditCards,
    selectedCard,
    selectCard,
    isCardExpired,
    handleDeleteCard,
  } = useCustomerCards({
    distributor: cartData.selectedDistributor,
    customerId,
    removeDraftCard,
    draftCardId: formField.cardId,
    setCardToCart: handleSetCardToCart,
    resetCardCart,
  });

  const isFirstStep = userProgressStep === 1;
  const hasMinimum =
    approvedDist?.minimumOrderValue &&
    approvedDist?.minimumOrderValueAmount > 0;

  const { products, moqField, ...rest } = errors || {};

  const errorToShow = {
    ...(!!Object.keys(errors).length &&
    // eslint-disable-next-line no-prototype-builtins
    errors.hasOwnProperty("products") &&
    Array.isArray(errors.products)
      ? {}
      : products !== undefined
      ? { products }
      : {}),
    ...(!!Object.keys(errors).length &&
    // eslint-disable-next-line no-prototype-builtins
    errors.hasOwnProperty("moqField") &&
    moqField !== undefined
      ? { moqField }
      : {}),
  };

  const isMOVHidden =
    hasMinimum && calculatedTotal >= approvedDist.minimumOrderValueAmount;

  const {
    unavailableProductsIds,
    openUnavailableProductDialog,
    titleUnavailableProductDialog,
    textUnavailableProductDialog,
    handleCloseUnavailableProductDialog,
  } = unavailableProductDialogState;

  return (
    <>
      <SelectCardDrawer
        {...{
          cardList: creditCards,
          selectedCard,
          handleSelectCard: (card) => {
            selectCard(card, (selectedCard) => {
              updateCartData({ card: selectedCard });
              clearErrors("cardId");
              setValue("cardId", card, {
                shouldValidate: true,
                shouldDirty: true,
              });
            });
          },
          isCardExpired,
          handleDeleteCard,
          setCardForEdit,
        }}
      />
      <ContactDrawer
        {...{
          contacts,
          handleAddContact: handleAddNewContact,
          editContact: selectedContact,
          deleteContact: onDeleteContact,
        }}
      />
      <NoteDrawer {...{ setValue, fieldValue: formField?.note?.text }} />
      <AddCardDrawer
        {...{
          customerId,
          handleSave: (_, dataCard) => {
            handleFetchCards(false, dataCard.defaultMethod ?? false);
          },
        }}
      />
      <EditCardDrawer
        {...{
          customerId,
          handleDeleteCard,
          card: cardForEdit,
          handleSave: (_, dataCard) =>
            handleFetchCards(false, dataCard.defaultMethod ?? false),
        }}
      />

      {!!openUnavailableProductDialog && (
        <UnavailableProductDialog
          open={openUnavailableProductDialog}
          title={titleUnavailableProductDialog}
          text={textUnavailableProductDialog}
          onCancel={handleCloseUnavailableProductDialog}
          onAccept={() =>
            handleRemoveUnavailableProducts(unavailableProductsIds)
          }
          isMobile
        />
      )}

      {isFirstStep &&
        hasMinimum &&
        !isMOVHidden &&
        !(draftsLoading && cartData.loadingProducts) && (
          <Stack gap="12px" position="fixed" sx={cl.moqWrapper}>
            <SummaryProgress
              value={calculatedTotal}
              hasMinimum={hasMinimum}
              maxValue={approvedDist.minimumOrderValueAmount}
            />
          </Stack>
        )}

      <Stack
        direction="column"
        sx={cl.contentWrapper}
        mt={isFirstStep ? `${isMOVHidden ? "43px" : "77px"}` : "44px"}
        height={
          isFirstStep && isMOVHidden
            ? "calc(100svh - 130px)"
            : isFirstStep
            ? "calc(100svh - 165px)"
            : "calc(100svh - 132px)"
        }
      >
        <Stack>
          {!!Object.keys(rest).length && !isFirstStep && (
            <Stack mt="16px">
              <ErrorsBlock {...{ errors: rest }} />
            </Stack>
          )}
          {!!Object.keys(errorToShow).length && isFirstStep && (
            <Stack mt="16px">
              <ErrorsBlock {...{ errors: errorToShow }} />
            </Stack>
          )}
          {isFirstStep && <ProductsList />}
        </Stack>
        {!isFirstStep && <DeliveryCartBlock {...{ creditCards }} />}
        {!!productsList.length && (
          <TotalCartBlock
            {...{
              calculatedDelivery,
              calculatedTotal,
              customerDiscount,
              grandTotalWithDiscount,
              casesCount,
            }}
          />
        )}
        {isFirstStep && !!productsList.length && <OptionsBlock />}
      </Stack>
      <CartActionBlock {...{ isFirstStep }} />
    </>
  );
};

import {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { func, object } from "prop-types";
import {
  Box,
  CircularProgress,
  Collapse,
  Stack,
  Typography,
} from "@mui/material";
import { ProductItem } from "./components";
import InfiniteScroll from "react-infinite-scroll-component";
import { containsEvery } from "helpers/helpers";
import { CartContext } from "pages/CartPage/CartPage";
import StyledPopper from "components/StyledPopper/StyledPopper";
import { useSelector } from "react-redux";
import { PRODUCT_TYPE_INVENTORY } from "utils/constants";

const isOrder = true;
const disableOutOfStock = true;

const hasItem = (arr1, arr2) =>
  arr1.some((el) => {
    return arr2.some((item) => item?.id === el?.id ?? null);
  });

export const setIndeterminate = (parentItem, isCheckedParent, checkedItems) => {
  if (isCheckedParent) return false;
  const childProducts = parentItem?.childProducts ?? [];
  if (!childProducts?.length) return false;

  return hasItem(childProducts, checkedItems);
};

export const ItemsListBlock = ({ productsState, handleFetchMore }) => {
  const { orderDirect } = useSelector(({ auth }) => auth.currentUser);

  const supplierWithOnlyOneProductWithVariations = useMemo(() => {
    if (orderDirect?.length !== 1) return false;
    const productsWithChildren = productsState?.list?.filter(
      (p) => p?.childProducts?.length
    );
    if (productsWithChildren?.length === 1) return true;
    return false;
  }, [orderDirect?.length, productsState?.list]);

  const {
    handleCheckProduct,
    cartData,
    products: addedProducts,
  } = useContext(CartContext);

  const { checkedProducts } = useMemo(() => cartData, [cartData]);

  // const [productsData, setProductsData] = useState({
  //   list: [],
  //   limit: 15,
  //   page: 1,
  //   count: 0,
  // });
  const productsRefs = useRef([]);
  const [expandedParentId, setExpandedParentId] = useState(null);
  const [currentProductRef, setCurrentProductRef] = useState(false);
  const [textProduct, setTextProduct] = useState("");

  const calcAvailable = useCallback((product) => {
    const onHand = product?.inventory?.onHand;
    const allocated = product?.inventory?.allocated;

    return onHand - allocated;
  }, []);

  const productsAvailableLessThanMinimum = useCallback(
    (product) => {
      const available = calcAvailable(product);
      if (product?.sellingOutOfStock || product?.childProducts?.length)
        return false;
      return available < product?.minOrderQTY;
    },
    [calcAvailable]
  );

  // If all products of the parent product are out of stock
  const calcProductOutOfStockForParent = useCallback(
    (product) => {
      if (product?.childProducts?.length && !product?.sellingOutOfStock) {
        return !product?.childProducts?.some(
          (prod) => calcAvailable(prod) > prod.minOrderQTY
        );
      }
      return false;
    },
    [calcAvailable]
  );

  const handleDisableIfChosenProduct = (product, parent) => {
    if (isOrder || !checkedProducts?.length) return false;

    const selectedParentId =
      checkedProducts?.[0]?.parentProduct?.id ||
      checkedProducts?.[0]?.parentId ||
      checkedProducts?.[0]?.id;

    const currentProductId = parent?.id || product?.id;

    return !(selectedParentId === currentProductId);
  };

  const handleProductsDisable = useCallback(
    (product, isChild) => {
      if (!addedProducts?.length) return false;

      if (isChild || !product.isMultiple)
        return (
          (product?.type === PRODUCT_TYPE_INVENTORY.inventory &&
            disableOutOfStock &&
            productsAvailableLessThanMinimum(product)) ||
          addedProducts?.some(
            (addedProduct) =>
              addedProduct?.id === product?.id ||
              addedProduct?.product?.id === product?.id
          )
        );

      return (
        (product?.type === PRODUCT_TYPE_INVENTORY.inventory &&
          disableOutOfStock &&
          productsAvailableLessThanMinimum(product)) ||
        containsEvery(
          product?.childProducts?.filter(
            // if isMultiple, check if Added products (formfield) contain
            // any of child products from this parent
            (childProduct) =>
              !addedProducts?.some(
                (prod) =>
                  prod?.id === childProduct?.id ||
                  prod?.product?.id === childProduct?.id
              )
          ),
          addedProducts
        )
      );
    },
    [productsAvailableLessThanMinimum, addedProducts]
  );

  const handleProductsChecked = (product) => {
    const disabled = handleProductsDisable(product);
    if (disabled) return false;

    if (product.isMultiple)
      return containsEvery(
        product?.childProducts?.filter(
          (childProduct) =>
            !addedProducts?.some(
              (prod) =>
                prod?.id === childProduct?.id ||
                prod?.product?.id === childProduct.id
            )
        ),
        checkedProducts
      );

    return checkedProducts.some(
      (checkedProduct) => checkedProduct?.id === product?.id
    );
  };

  // const handleFetchMore = () => {
  //   getProductsService({ limit: productsData.limit, page: productsData.page })
  //     .then(({ rows, count }) => {
  //       setProductsData((prev) => ({
  //         ...prev,
  //         list: [...prev.list, ...rows],
  //         count,
  //         page: prev.page + 1,
  //       }));
  //     })
  //     .catch((err) => {
  //       error(err?.response?.data?.message);
  //     });
  // };

  // useEffect(() => {
  //   getProductsService({ limit: 15, page: 1 })
  //     .then(({ rows, count }) => {
  //       setProductsData((prev) => ({
  //         ...prev,
  //         list: rows,
  //         count,
  //         page: prev.page + 1,
  //       }));
  //     })
  //     .catch((err) => {
  //       error(err?.response?.data?.message);
  //     });
  // }, []);

  return (
    <Stack
      sx={{
        height: "281px",
        // overflow: "overlay",
        overflow: "scroll",
        border: "0.5px solid #D5D9D9",
        borderRadius: "0 0 4px 4px",
      }}
      id="all-products-scroll-table"
    >
      {productsState.loading && (
        <Box
          sx={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            height: "281px",
            alignItems: "center",
            width: "708px",
            zIndex: 10,
            backgroundColor: "rgba(112, 112, 112, 0.05)",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <StyledPopper
        style={{ zIndex: 1401, whiteSpace: "pre-wrap" }}
        open={!!currentProductRef && open}
        anchorEl={productsRefs.current?.[currentProductRef] || null}
        text={textProduct}
        modifiers={[
          {
            name: "offset",
            options: { offset: [-258, -4] },
          },
        ]}
        placement="top"
        transition
        aria-hidden="true"
      />
      <InfiniteScroll
        dataLength={productsState.list.length}
        next={handleFetchMore}
        loader={<CircularProgress sx={{ ml: "50%", mt: "2px" }} size="30px" />}
        hasMore={productsState.list.length < productsState.count}
        scrollableTarget="all-products-scroll-table"
      >
        {productsState.list.length ? (
          productsState.list.map((product) => {
            const disableIfChosenProduct = handleDisableIfChosenProduct(
              product,
              null
            );

            const isChecked = handleProductsChecked(product);

            const indeterminateParent = setIndeterminate(
              product,
              isChecked,
              checkedProducts
            );

            const isMissingInfo = product?.missingFields;
            const getAvailableQTY = calcAvailable(product);
            return (
              <Fragment key={product.id}>
                <ProductItem
                  isOrder={isOrder}
                  ref={(el) => (productsRefs.current[product.id] = el)}
                  onMouseEnter={() => {
                    if (
                      product?.type === PRODUCT_TYPE_INVENTORY.inventory &&
                      productsAvailableLessThanMinimum(product) &&
                      !product?.isMultiple &&
                      disableOutOfStock
                    ) {
                      setCurrentProductRef(product?.id);
                      setTextProduct(
                        getAvailableQTY < 0
                          ? `Out of stock, Minimum Order QTY: ${product?.minOrderQTY}`
                          : `Cannot add this product to the cart.\n Items Available: ${getAvailableQTY}, Minimum Order QTY: ${product?.minOrderQTY}`
                      );
                    }
                  }}
                  onMouseLeave={() => {
                    setCurrentProductRef(false);
                    setTextProduct("");
                  }}
                  addedProducts={addedProducts}
                  key={product.id}
                  product={product}
                  expandedParentId={expandedParentId}
                  parentInOrder={
                    product.isMultiple &&
                    containsEvery(
                      product.childProducts.filter(
                        // if isMultiple, check if Added products (formfield) contain
                        // any of child products from this parent
                        (childProduct) =>
                          !addedProducts?.some(
                            (prod) => prod.id === childProduct.id
                          )
                      ),
                      addedProducts
                    )
                  }
                  handleCheckProduct={handleCheckProduct}
                  handleExpandVariations={(id) => {
                    if (id === expandedParentId)
                      return setExpandedParentId(null);
                    setExpandedParentId(id);
                  }}
                  isChecked={isChecked}
                  isMissingInfo={isMissingInfo}
                  disabled={
                    handleProductsDisable(product, false) ||
                    disableIfChosenProduct ||
                    !!isMissingInfo?.length
                  }
                  indeterminateParent={indeterminateParent}
                  setExpandedParentId={setExpandedParentId}
                  disableIfChosenProduct={disableIfChosenProduct}
                  outOfStock={
                    (product?.type === PRODUCT_TYPE_INVENTORY.inventory &&
                      disableOutOfStock &&
                      productsAvailableLessThanMinimum(product)) ||
                    (product?.type === PRODUCT_TYPE_INVENTORY.inventory &&
                      calcProductOutOfStockForParent(product))
                  }
                  hideAvailable={product?.sellingOutOfStock}
                  supplierWithOnlyOneProductWithVariations={
                    supplierWithOnlyOneProductWithVariations &&
                    !!product?.childProducts?.length
                  }
                />
                <Collapse in={expandedParentId === product.id} timeout={700}>
                  {product.childProducts?.map((children, indexChild) => {
                    children.sellingOutOfStock = product?.sellingOutOfStock;
                    children.minOrderQTY = product?.minOrderQTY;

                    const disableIfChosenProduct = handleDisableIfChosenProduct(
                      children,
                      product
                    );
                    return (
                      <ProductItem
                        ref={(el) => (productsRefs.current[children.id] = el)}
                        onMouseEnter={() => {
                          if (
                            product?.type ===
                              PRODUCT_TYPE_INVENTORY.inventory &&
                            productsAvailableLessThanMinimum(children)
                          ) {
                            setCurrentProductRef(children?.id);
                            setTextProduct(
                              calcAvailable(children) < 0
                                ? `Out of stock, Minimum Order QTY: ${product?.minOrderQTY}`
                                : `Cannot add this product to the cart.\n Items Available: ${calcAvailable(
                                    children
                                  )}, Minimum Order QTY: ${
                                    product?.minOrderQTY
                                  }`
                            );
                          }
                        }}
                        onMouseLeave={() => {
                          setCurrentProductRef(false);
                          setTextProduct("");
                        }}
                        key={children.id}
                        product={children}
                        parent={product}
                        isChild
                        handleCheckProduct={handleCheckProduct}
                        isChecked={checkedProducts.some(
                          (checkedProduct) => checkedProduct.id === children.id
                        )}
                        isMissingInfo={isMissingInfo}
                        disabled={
                          handleProductsDisable(children, true) ||
                          disableIfChosenProduct ||
                          !!isMissingInfo?.length
                        }
                        disableIfChosenProduct={disableIfChosenProduct}
                        outOfStock={
                          product?.type === PRODUCT_TYPE_INVENTORY.inventory &&
                          disableOutOfStock &&
                          productsAvailableLessThanMinimum(children)
                        }
                        lastChild={
                          product?.childProducts.length === indexChild + 1
                        }
                        hideAvailable={product?.sellingOutOfStock}
                      />
                    );
                  })}
                </Collapse>
              </Fragment>
            );
          })
        ) : (
          <Typography
            fontWeight="300"
            fontSize="16px"
            textAlign="center"
            width="100%"
            color="#6A6A6A"
            paddingTop="20px"
            paddingBottom="20px"
          >
            No products found
          </Typography>
        )}
      </InfiniteScroll>
    </Stack>
  );
};

ItemsListBlock.propTypes = { productsState: object, handleFetchMore: func };
ItemsListBlock.defaultProps = {};
